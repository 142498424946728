import React, { useContext, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { Card, CardImage, Content, Loading, FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import track from 'react-tracking';

import Layout from 'components/Layout';
import Message from 'components/Message';
import { APPOINTMENT_AREA_QUERY } from 'pages/Consultation/components/Start/Start.query';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import styles from 'pages/Consultation/components/Start/Start.scss';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import ConsultationHeading from 'pages/Consultation/components/ConsultationHeading';
import Box from 'components/Box';
import { getAreaName, getTrackingStartData } from 'pages/Consultation/utils/area';

const Start = (props) => {
  const tracking = useTracking(props, 'Start');
  const { data: areaData, loading, error } = useQuery(APPOINTMENT_AREA_QUERY);
  const { dispatch } = useContext(ConsultationContext);
  const { getAppointmentAreas } = areaData || {};
  const { areas } = getAppointmentAreas || {};

  const handleOnCardClick = useCallback(
    (area) => (event) => {
      tracking(event, getTrackingStartData(area));
      dispatch({
        type: 'SET_AREA',
        payload: area,
      });
    },
    [dispatch, tracking],
  );

  const handleKeyDown = useCallback(
    (area) => (event) => {
      const { key } = event;
      if (key === 'Enter') {
        tracking(event, getTrackingStartData(area));
        dispatch({
          type: 'SET_AREA',
          payload: area,
        });
      }
    },
    [dispatch, tracking],
  );

  const renderArea = (area) => (
    <li key={area.externId}>
      <button
        onClick={handleOnCardClick(area)}
        onKeyDown={handleKeyDown(area)}
        className={styles.area}
        data-purpose="consultation.start.area"
      >
        <Card className={styles.areaCard}>
          <CardImage isBackground source={area.thumbnail} className={styles.areaImage} />
        </Card>
        <div className={styles.areaDescriptionWrapper}>
          <span className={styles.areaCategory}>{getAreaName(area.publicName)}</span>
          <Content
            className={styles.areaLength}
            content={<Message code="consultation.start.duration" values={{ min: area.length }} />}
          />
        </div>
      </button>
    </li>
  );

  const renderAreas = () => (loading ? <Loading /> : areas.map((area) => renderArea(area)));

  return (
    <Layout margin="none">
      <Box marginBottom="small" className={styles.heading}>
        <Message code={['consultation.start.title', 'consultation.start.subTitle']}>
          {([title, subTitle]) => <ConsultationHeading content={title} subContent={subTitle} />}
        </Message>
      </Box>
      {error ? (
        <FeedbackCard variant="error">
          <Content
            content={<Message code="consultation.start.error.loadingFailed" />}
            size="sm"
            tag="p"
          />
        </FeedbackCard>
      ) : (
        <ul className={classnames(!loading && styles.grid, areas?.length < 4 && styles.smallGrid)}>
          {renderAreas()}
        </ul>
      )}
    </Layout>
  );
};

export default track(tagComponent('Start'))(Start);

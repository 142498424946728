import React, { useContext, useCallback } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { Content, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { googleCalendar, iCal, microsoftOutlook } from '@xxxlgroup/hydra-icons';

import Layout from 'components/Layout';
import Link from 'components/WebshopLink';
import Message from 'components/Message';

import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import getAddress from 'pages/Consultation/utils/getAddress';
import { createGoogleCalendarLink, createIcsLink } from 'utils/calendarLinks';
import parseTimeslotDate from 'pages/Consultation/utils/parseTimeslotDate';
import useDayjsUtc from 'hooks/useDayjsUtc';

import styles from 'pages/Consultation/components/SummaryConfirmation/SummaryConfirmation.scss';

const SummaryConfirmation = (props) => {
  const { dateFormat, timeFormat } = props;
  const { state, dispatch } = useContext(ConsultationContext);
  const dayjsUtc = useDayjsUtc();
  const {
    area,
    timeslot,
    store: { address, name },
    trackingData,
  } = state;

  const timeSlotLength = area && area.length ? area.length : null;

  const parseTimeSlot = parseTimeslotDate(timeslot, dayjsUtc);
  const fullAddress = getAddress(address);
  const tracking = useTracking(props, 'SummaryConfirmation');

  const handleCancelClick = useCallback(
    (event) => {
      const data = { area: trackingData };
      tracking(event, data);
      dispatch({
        type: 'SET_ACTIVE_VIEW',
        payload: 'cancelInquiry',
      });
    },
    [dispatch, tracking, trackingData],
  );

  return (
    <Message
      code={[
        'consultation.finish.area',
        'consultation.finish.date',
        'consultation.finish.appointment',
        'consultation.finish.duration',
        'consultation.finish.location',
        'consultation.finish.cancel',
        'consultation.add.to.outlook',
        'consultation.add.to.ical',
        'consultation.add.to.google.calendar',
        'consultation.calendarEvent.title',
        'consultation.calendarEvent.description',
      ]}
    >
      {([
        areaText,
        date,
        appointment,
        duration,
        locationText,
        cancel,
        outlookText,
        icalText,
        googleCalendarText,
        titleEvent,
        descriptionEvent,
      ]) => (
        <Layout variant="full" className={styles.summaryGrid}>
          {area && (
            <>
              <Content content={areaText} className={styles.title} />
              <Content content={area.publicName} className={styles.value} />
            </>
          )}

          <Content content={date} className={styles.title} />
          <Content content={parseTimeSlot.format(dateFormat)} className={styles.value} />
          <Content content={duration} className={styles.title} />
          <div>
            <Content
              content={
                <Message
                  code="consultation.finish.durationRange"
                  values={{
                    from: parseTimeSlot.format(timeFormat),
                    to: parseTimeSlot.add(timeSlotLength, 'minutes').format(timeFormat),
                  }}
                />
              }
              className={classnames(styles.value, styles.duration)}
            />
            <Content
              content={
                <Message code="consultation.header.length" values={{ length: timeSlotLength }} />
              }
              className={classnames(styles.additionalDescription, styles.durationValue)}
            />
          </div>
          <Content content={locationText} className={styles.title} />
          <div className={styles.value}>
            <Content content={name} className={styles.value} />
            <Content content={fullAddress} className={styles.additionalDescription} />
          </div>
          {state.activeView === 'finish' && (
            <>
              <Content content={appointment} className={styles.title} />
              <div>
                <Link
                  as={
                    <HydraLink
                      data-purpose="consultation.outlook.calender.button"
                      glyphBefore={microsoftOutlook}
                      layout="block"
                      theme="coal"
                    />
                  }
                  className={styles.calendarLink}
                  href={createIcsLink(
                    titleEvent,
                    descriptionEvent,
                    fullAddress,
                    timeslot.start,
                    timeSlotLength,
                  )}
                  target="_blank"
                >
                  {outlookText}
                </Link>

                <Link
                  as={
                    <HydraLink
                      data-purpose="consultation.ical.calender.button"
                      glyphBefore={iCal}
                      layout="block"
                      theme="coal"
                    />
                  }
                  href={createIcsLink(
                    titleEvent,
                    descriptionEvent,
                    fullAddress,
                    timeslot.start,
                    timeSlotLength,
                  )}
                  className={styles.calendarLink}
                  target="_blank"
                >
                  {icalText}
                </Link>

                <Link
                  as={
                    <HydraLink
                      data-purpose="consultation.add.to.google.calendar"
                      glyphBefore={googleCalendar}
                      layout="block"
                      theme="coal"
                    />
                  }
                  className={styles.calendarLink}
                  href={createGoogleCalendarLink(
                    titleEvent,
                    descriptionEvent,
                    fullAddress,
                    timeslot.start,
                    timeSlotLength,
                  )}
                  target="_blank"
                >
                  {googleCalendarText}
                </Link>

                <HydraLink
                  data-purpose="consultation.header.cancel.button"
                  onClick={handleCancelClick}
                  theme="coal"
                  layout="block"
                  className={styles.cancelAppointment}
                >
                  {cancel}
                </HydraLink>
              </div>
            </>
          )}
        </Layout>
      )}
    </Message>
  );
};

SummaryConfirmation.propTypes = {
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};

SummaryConfirmation.defaultProps = {
  dateFormat: 'dddd, DD. MMMM YYYY',
  timeFormat: 'HH:mm',
};

export default track(tagComponent('SummaryConfirmation'))(SummaryConfirmation);

export const WHITELIST_DOMAINS = ['kuechenplaner.xxxlutz.de', 'go-2b-planer.de'];

const getParentOrigin = () => {
  const isLocationDistinct = window.location !== window.parent.location;

  if (isLocationDistinct) {
    const parentOrigin = (document.referrer || '').toString();
    return new URL(parentOrigin).origin;
  }

  return document.location.origin;
};

export const getHostFromWhitelist = (whitelistDomains = WHITELIST_DOMAINS) => {
  const parentOrigin = getParentOrigin();
  let url = null;
  whitelistDomains.forEach((domain) => {
    if (parentOrigin.includes(domain)) {
      url = parentOrigin;
    }
  });

  return url;
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Content, Heading } from '@xxxlgroup/hydra-ui-components';
import Box from 'components/Box';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import classnames from 'classnames';
import styles from 'pages/Consultation/components/ConsultationHeading/ConsultationHeading.scss';

const ConsultationHeading = ({ content, subContent, noSubContentOnMobile }) => {
  const { state } = useContext(ConsultationContext);
  return (
    <>
      <Box className={styles.heading}>
        <Heading
          content={content}
          level={2}
          SEO={state?.headlineSeo}
          noMargin
          role="alert"
          aria-live="polite"
        />
      </Box>
      {subContent && (
        <Box
          marginBottom="xxsmall"
          className={classnames(noSubContentOnMobile && styles.hideSubContent)}
        >
          <Content content={subContent} />
        </Box>
      )}
    </>
  );
};

ConsultationHeading.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
  subContent: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  noSubContentOnMobile: PropTypes.bool,
};

ConsultationHeading.defaultProps = {
  subContent: null,
  noSubContentOnMobile: false,
};

export default React.memo(ConsultationHeading);

import React from 'react';
import track from 'react-tracking';

import { tagComponent } from 'utils/tracking/tracking';
import Layout from 'components/Layout';
import CompletionMessage from 'pages/Consultation/components/CompletionMessage';
import SummaryConfirmation from 'pages/Consultation/components/SummaryConfirmation';
import styles from 'pages/Consultation/components/Finish/Finish.scss';

const Finish = () => (
  <Layout variant="narrow" className={styles.finish}>
    <CompletionMessage />
    <SummaryConfirmation />
  </Layout>
);

export default track(tagComponent('Finish'))(Finish);

import React, { useContext, useCallback, useMemo } from 'react';
import track from 'react-tracking';
import { Button } from '@xxxlgroup/hydra-ui-components';

import useRoutes from 'hooks/useRoutes';
import Layout from 'components/Layout';
import Message from 'components/Message';
import Link from 'components/WebshopLink';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import CompletionMessage from 'pages/Consultation/components/CompletionMessage';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import useIsModeHeadless from 'hooks/useIsModeHeadless';

import styles from 'pages/Consultation/components/CancelConfirmed/CancelConfirmed.scss';

const CancelConfirmed = (props) => {
  const routes = useRoutes();
  const { state, dispatch } = useContext(ConsultationContext);
  const tracking = useTracking(props, 'CancelConfirmed');
  const isModeHeadless = useIsModeHeadless();

  const data = useMemo(() => ({ area: state.trackingData }), [state]);

  const handleResetClick = useCallback(
    (event) => {
      tracking(event, data);
      dispatch({
        type: 'RESET_STATE',
      });
    },
    [data, dispatch, tracking],
  );

  const handleNewAppointment = useCallback(
    (event) => {
      tracking(event, data);
    },
    [data, tracking],
  );

  return (
    <Layout variant="narrow" className={styles.cancelConfirmed}>
      <CompletionMessage />
      <Layout variant="full" margin="none" className={styles.buttonsWrapper}>
        <Layout variant="full" margin="none" grid="grid-2" className={styles.buttonsColumn}>
          {!isModeHeadless && (
            <Link
              href={routes.homePage}
              as={
                <Button
                  data-purpose="consultation.cancelConfirmed.backToHomepage.button"
                  onClick={handleNewAppointment}
                  variant="tertiary"
                />
              }
            >
              <Message code="consultation.confirmCancel.back" />
            </Link>
          )}
          <Button
            data-purpose="consultation.cancelConfirmed.newAppointment.button"
            onClick={handleResetClick}
            variant="primary"
          >
            <Message code="consultation.confirmCancel.newConsultation" />
          </Button>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default track(tagComponent('CancelConfirmed'))(CancelConfirmed);

import { gql } from '@apollo/client';

export const APPOINTMENT_AREA_QUERY = gql`
  query getAppointmentAreas {
    getAppointmentAreas {
      areas {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
    }
  }
`;

import { gql } from '@apollo/client';

import { cart } from 'graphql-fragments';

export const APPLY_VOUCHER_MUTATION = gql`
  mutation applyVoucher(
    $cartId: String!
    $bonusCard: String
    $code: String
    $lastName: String
    $pin: String
  ) {
    applyVoucher(
      cartId: $cartId
      bonuscard: $bonusCard
      code: $code
      lastName: $lastName
      pin: $pin
    ) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_BONUSCARD_MUTATION = gql`
  mutation updateCartBonusCard(
    $cartId: String!
    $bonusCard: String
    $lastName: String
  ) {
    updateCartBonusCard(
      cartId: $cartId
      bonuscard: $bonusCard
      lastName: $lastName
    ) {
      ${cart}
    }
  }
`;

export const REMOVE_VOUCHER_MUTATION = gql`
  mutation removeVoucher($cartId: String!, $code: String!) {
    removeVoucher(cartId: $cartId, code: $code) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_ADDRESSES_MUTATION = gql`
  mutation updateCartAddresses($cartId: String!, $data: CartAddressesRequestInput!) {
    updateCartAddresses(cartId: $cartId, data: $data) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_ADDRESSES_EXPRESS_PAYMENT_MUTATION = gql`
  mutation updateCartAddressesExpressPayment($cartId: String!, $data: CartAddressesExpressPaymentRequestInput!) {
    updateCartAddressesExpressPayment(cartId: $cartId, data:$data) {
      ${cart}
    }
  }
`;

export const UPDATE_CART_PAYMENTMODE_MUTATION = gql`
  mutation updateCartPaymentMode($cartId: String!, $data: PaymentModeRequestInput!) {
    updateCartPaymentMode(cartId: $cartId, data: $data) {
      ${cart}
    }
  }
`;

export const SET_ADDITIONAL_CART_DATA = gql`
  mutation setAdditionalCartData($cartId: String!, $additionalData: AdditionalDataInput!) {
    setAdditionalCartData(cartId: $cartId, additionalData: $additionalData) {
      ${cart}
    }
  }
`;

export const UPDATE_POSTAL_CODE_MUTATION = gql`
  mutation updatePostalCode($cartId: String!, $postalCode: String!) {
    updatePostalCode(cartId: $cartId, postalCode: $postalCode) {
      ${cart}
    }
  }
`;

export const GET_ADDRESS_VALIDATION = gql`
  mutation getAddressValidation(
    $country: String!
    $street: String!
    $postcode: String!
    $town: String!
  ) {
    getAddressValidation(country: $country, street: $street, postcode: $postcode, town: $town) {
      exactMatch
      selectedAddress {
        street
        postcode
        town
      }
      suggestedAddressList {
        street
        postcode
        town
        priority
      }
      country
    }
  }
`;

export const VALIDATE_PHONE_NUMBER_MUTATION = gql`
  mutation validatePhoneNumber($countryIso: String!, $phoneNumber: String!) {
    validatePhoneNumber(countryIso: $countryIso, phoneNumber: $phoneNumber) {
      isValid
      isPossible
      phoneNumberCountry
      internationallyFormattedNumber
      locallyFormattedNumber
    }
  }
`;

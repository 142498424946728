import React from 'react';
import { Content, FeedbackCard, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';

import styles from 'pages/Consultation/components/ErrorInfoCard/ErrorInfoCard.scss';

const ErrorInfoCard = () => {
  const prefixError = 'consultation.general.error';
  const [errorTitle, errorText, errorContactText, phoneNumber, openingHours] = useMessage([
    `${prefixError}.title`,
    `${prefixError}.text`,
    `${prefixError}.contact.text`,
    `${prefixError}.phone.number`,
    `${prefixError}.opening.hours`,
  ]);

  return (
    <div className={styles.content}>
      <FeedbackCard variant="warning" title={errorTitle} className={styles.feedbackCard}>
        <Content content={errorText} className={styles.feedbackCardMessage} />
      </FeedbackCard>
      <Content content={errorContactText} className={styles.contactText} />
      {phoneNumber && (
        <Link
          as={<HydraLink theme="coal" typography="small" underline />}
          href={`tel:${phoneNumber}`}
          target="_blank"
          className={styles.phoneNumber}
        >
          {phoneNumber}
        </Link>
      )}
      {openingHours && <Content className={styles.openingHours} content={openingHours} />}
    </div>
  );
};

export default ErrorInfoCard;

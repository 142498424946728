import React, { useCallback, useContext } from 'react';
import track from 'react-tracking';
import { Skeleton } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import Layout from 'components/Layout';
import Message from 'components/Message';
import LocationFinder from 'components/LocationFinder';
import Subsidiaries from 'pages/Consultation/components/SubsidiarySearch/components/Subsidiaries';
import useSubsidiariesAndUpdateStore from 'pages/Consultation/components/SubsidiarySearch/useSubsidiariesAndUpdateStore';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import ConsultationHeading from 'pages/Consultation/components/ConsultationHeading';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import ErrorInfoCard from 'pages/Consultation/components/ErrorInfoCard';

import styles from 'pages/Consultation/components/SubsidiarySearch/SubsidiarySearch.scss';

const SubsidiarySearch = (props) => {
  const { subsidiaries, refetchSubsidiariesWithZip, loading, error } =
    useSubsidiariesAndUpdateStore();
  const tracking = useTracking(props, 'SubsidiarySearch');
  const {
    state: { trackingData },
  } = useContext(ConsultationContext);

  const handleSubmitZipCode = useCallback(
    ({ zipCode, setAriaLiveMessage }) => {
      const suggestionData = { zipCode, area: trackingData };
      tracking(suggestionData);
      refetchSubsidiariesWithZip(zipCode);
      setAriaLiveMessage?.('locationFinder.aria.live.only.available.subsidiaries', {
        availableCount: subsidiaries?.length,
      });
    },
    [refetchSubsidiariesWithZip, subsidiaries?.length, tracking, trackingData],
  );

  if (error) {
    return <ErrorInfoCard />;
  }

  return (
    <Layout variant="narrow" margin="none">
      <Message code={['consultation.search.subTitle', 'consultation.search.title']}>
        {([subTitle, title]) => (
          <ConsultationHeading content={title} subContent={subTitle} noSubContentOnMobile />
        )}
      </Message>
      <LocationFinder className={styles.locationSearch} onSubmitZipCode={handleSubmitZipCode} />
      {loading && <Skeleton className={styles.skeleton} count={4} />}
      {!isArrayEmpty(subsidiaries) && !loading && <Subsidiaries subsidiaries={subsidiaries} />}
    </Layout>
  );
};

export default track(tagComponent('SubsidiarySearch'))(SubsidiarySearch);

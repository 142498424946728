import dayjs from 'dayjs';

// the time zone is removed, because it doesn't matter.
const timeStartEvent = (date) => dayjs.utc(date).format('YYYYMMDDTHHmmss');

const timeEndEvent = (date, durationEvent, unit = 'minute') =>
  dayjs.utc(date).add(durationEvent, unit).format('YYYYMMDDTHHmmss');

export const createIcsLink = (title, description, location, startEvent, durationEvent) => {
  const config = 'data:text/calendar;charset=utf8';
  const start = 'BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART';
  const end =
    'VALARM%0AACTION:DISPLAY%0ADESCRIPTION:REMINDER%0ATRIGGER:-PT24H%0AEND:VALARM%0AEND:VEVENT%0AEND:VCALENDAR';
  const timeStart = timeStartEvent(startEvent);
  const timeEnd = timeEndEvent(startEvent, durationEvent);
  return `${config},${start}:${timeStart}%0ADTEND:${timeEnd}%0ASUMMARY:${title}%0ADESCRIPTION:${description}%0ALOCATION:${location}%0ABEGIN:${end}`;
};

export const createGoogleCalendarLink = (
  title,
  description,
  location,
  startEvent,
  durationEvent,
) => {
  const timeStart = timeStartEvent(startEvent);
  const timeEnd = timeEndEvent(startEvent, durationEvent);
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${timeStart}%2F${timeEnd}`;
};

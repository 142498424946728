import OptionalSelectField from 'components/FormFields/OptionalSelectField';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FieldProps } from 'components/Form/components/Field/Field.types';
import React, { useMemo } from 'react';
import useMessage from 'components/Message/useMessage';

interface GenderFieldProps extends Omit<FieldProps, 'name' | 'prefix'> {
  featureFlag?: string;
  /** feature flag that determines if the field is shown */
  visibleFeatureFlag?: string;
  /** is value translated defines if we should pass to backend translated value of param */
  isValueTranslated?: boolean;
  /** third gender feature flag controls whether or not the third gender (other) is shown */
  thirdGenderFeatureFlag?: string;
  /** prefixes the name-string */
  prefix?: string;
}

/**
 * This is a generic <GenderField /> component, which is used by MANY consumers,
 * which means do NOT ADD specific business implementation for your feature.
 * The change will be applied to all consumers and must be aligned with all of them.
 */
const GenderField: React.FC<GenderFieldProps> = ({
  className,
  prefix = '',
  featureFlag = 'poseidon.formfield.address.general.gender.required',
  visibleFeatureFlag = 'poseidon.formfield.address.general.gender.visible',
  isFirstError = false,
  isRequired: requiredByConsumer = null,
  isValueTranslated = false,
  thirdGenderFeatureFlag = '',
}) => {
  const [requiredByConfiguration, visible, hasOtherGender] = useFeatureFlags([
    featureFlag,
    visibleFeatureFlag,
    thirdGenderFeatureFlag,
  ]);

  const required =
    typeof requiredByConsumer === 'boolean' ? requiredByConsumer : requiredByConfiguration;

  const translations = useMessage(
    hasOtherGender
      ? [
          `wxs.form.select.salutation.male`,
          `wxs.form.select.salutation.female`,
          `wxs.form.select.salutation.other`,
        ]
      : [`wxs.form.select.salutation.male`, `wxs.form.select.salutation.female`],
  );

  const mapGenderOptions = useMemo(
    () =>
      hasOtherGender
        ? [
            { item: translations[0], value: isValueTranslated ? translations[0] : 'MALE' },
            { item: translations[1], value: isValueTranslated ? translations[1] : 'FEMALE' },
            { item: translations[2], value: isValueTranslated ? translations[2] : 'OTHER' },
          ]
        : [
            { item: translations[0], value: isValueTranslated ? translations[0] : 'MALE' },
            { item: translations[1], value: isValueTranslated ? translations[1] : 'FEMALE' },
          ],
    [hasOtherGender, isValueTranslated, translations],
  );

  if (!visible) {
    return null;
  }

  return (
    <OptionalSelectField
      autoComplete="honorific-prefix"
      labelCode="wxs.form.select.salutation.label"
      name={prefix ? `${prefix}gender` : 'gender'}
      options={mapGenderOptions}
      isFirstError={isFirstError}
      placeholderCode="wxs.form.select.salutation.placeholder"
      isRequired={required}
      withOptionalSymbol
      className={className}
    />
  );
};

export default GenderField;

import { gql } from '@apollo/client';
import {
  dimensions,
  brands,
  mediaData,
  seoData,
  breadcrumbs,
  commonPriceData,
  energyEfficiencyData,
} from 'graphql-fragments/fragments';

export const PRODUCT_QUERY = gql`
  ${dimensions}
  ${brands}
  ${mediaData}
  ${seoData}
  ${breadcrumbs}
  ${commonPriceData}
  ${energyEfficiencyData}
  query product($productCode: String!) {
    getProduct(productCode: $productCode) {
      manufactureCountry {
        name
      }
      originCountry {
        name
      }
      produceCountry {
        name
      }
      attributes {
        colorMapping {
          code
          targetColor
          webColor
        }
        dimensions {
          ...dimensions
          strRepresentation
          strRepresentationKey
        }
        material
      }
      affirmators {
        title
        iconKey
        link
      }
      categoryId
      color
      name
      code
      timendoMasterId
      timendoAreaCmsPage
      description
      transitionalDescription
      teaserText
      url
      baseProduct
      onlineOnly
      augmentedReality {
        androidUrl
        iosUrl
      }
      availabilityStatus
      legalInformationShown
      reservationData {
        maxReservableAmount
        reservable
      }
      additionalProductExpenses {
        price
        type
        includedInProductPrice
        restType
      }
      categories {
        name
        code
      }
      configurable
      configuratorData {
        configurationId
        configurationSystemId
        restType
      }
      configuratorUrl
      configuredProduct
      configuredProductData {
        attachments {
          altText
          code
          url
        }
        images {
          altText
          url
        }
        configuredId
        productNumber
        description
        name
        restType
        summary
      }
      brands {
        ...brands
      }
      liveshopping
      deliveryData {
        assembly
        bulkyProduct
        customDeliveryType
        deliveryCostsCharged
        deliveryIndicator
        deliveryInformationForCustomers {
          code
          name
          value
          valueId
        }
      }
      mediaData {
        ...mediaData
      }
      characteristicData {
        colorMapping {
          code
          targetColor
          webColor
        }
        detailAttributes {
          name
          attributes {
            name
            value
          }
        }
      }
      eyecatchers {
        code
        style
      }
      shopInformation {
        isExternal
        shopId
        shopName
      }
      seoData {
        ...seoData
      }
      breadcrumbs {
        ...breadcrumbs
      }
      variantContainerData {
        variantGroups {
          name
          title
          variantValues {
            colorTitle
            colorMapping
            restType
            enabled
            selected
            value
            product {
              code
              color
              url
            }
            productPicture {
              altText
              cdnFilename
              hashCode
              fileExtension
              fileType
              mediaProvider
              pictureHeight
              pictureWidth
              realFilename
              restType
              seoText
            }
            targetColor
          }
        }
      }
      priceData {
        ...commonPriceData
        currentPrice {
          endTime
          specialOfferTypeData {
            numberOfAsterisks
          }
        }
        postCost {
          value
        }
        deliveryCost {
          value
        }
        deliveryMethods {
          code
          price {
            value
          }
        }
        assemblyCost {
          value
        }
        shippingCost {
          value
        }
        promotionalMessageShown
      }
      style {
        code
        name
      }
      energyEfficiencyData {
        ...energyEfficiencyData
      }
      orderData {
        buyable
        maxOrderableAmount
        sellable
      }
      summary
      additionalDescription {
        name
        attributes {
          name
          value
        }
      }
      eligibleForFreeDelivery
      statisticKPI
      hasRatings
      relatedProductAvailability {
        hasAccessories
      }
      productServices {
        code
        price
        type
      }
    }
  }
`;

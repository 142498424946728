import { useLazyQuery } from '@apollo/client';
import {
  APPOINTMENT_SLOTS_QUERY,
  APPOINTMENT_SLOTS_WITH_INFO_QUERY,
} from 'pages/Consultation/components/TimeBlocks/TimeBlocks.query';
import { useContext, useEffect, useCallback } from 'react';
import useDayjs from 'hooks/useDayjs';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const getAreaId = (subsidiary, state) => subsidiary.timendoInterventionId || state.interventionId;
const getStoreId = (subsidiary, state) => subsidiary.timendoGroupId || state.groupId;
const getLoading = (loadingV1, loadingV2, errorV1, calledV2) =>
  loadingV1 || loadingV2 || (errorV1 && !calledV2);
const getError = (errorV1, errorV2) => (errorV1 && errorV2 ? errorV1 : null);

const useLazyAppointmentSlots = (subsidiary) => {
  const { dispatch, state } = useContext(ConsultationContext);
  // If interventionId is set, this will be the first base view. Area and store aren't loaded yet.
  const query = state.interventionId ? APPOINTMENT_SLOTS_WITH_INFO_QUERY : APPOINTMENT_SLOTS_QUERY;

  const [
    loadAppointmentsV1,
    { loading: loadingV1, data: timeslotsDataV1, error: errorV1, called: calledV1 },
  ] = useLazyQuery(query);
  const [
    loadAppointmentsV2,
    { loading: loadingV2, data: timeslotsDataV2, error: errorV2, called: calledV2 },
  ] = useLazyQuery(query);
  const dayjs = useDayjs();
  const areaId = getAreaId(subsidiary, state);
  const storeId = getStoreId(subsidiary, state);

  const startV1 = dayjs().startOf('month').format(DATE_FORMAT);
  const endV1 = dayjs().endOf('month').add(3, 'month').format(DATE_FORMAT);

  const startV2 = dayjs().format(DATE_FORMAT);
  const endV2 = dayjs().add(30, 'day').format(DATE_FORMAT);

  const hasTimeslotsV1 = !isArrayEmpty(
    timeslotsDataV1?.getAppointmentTimeslots?.availableTimeslots,
  );
  const hasTimeslotsV2 = !isArrayEmpty(
    timeslotsDataV2?.getAppointmentTimeslots?.availableTimeslots,
  );
  const timeslotsData = hasTimeslotsV1 ? timeslotsDataV1 : timeslotsDataV2;

  const getAppointments = useCallback(() => {
    loadAppointmentsV1({
      variables: { areaId, storeId, start: startV1, end: endV1 },
    });
  }, [loadAppointmentsV1, areaId, storeId, startV1, endV1]);

  useEffect(() => {
    if (calledV1 && !loadingV1 && !hasTimeslotsV1 && !calledV2) {
      loadAppointmentsV2({
        variables: { areaId, storeId, start: startV2, end: endV2 },
      });
    }
  }, [
    areaId,
    calledV1,
    calledV2,
    endV2,
    hasTimeslotsV1,
    loadAppointmentsV2,
    loadingV1,
    startV2,
    storeId,
  ]);

  useEffect(() => {
    if (hasTimeslotsV1 || hasTimeslotsV2) {
      dispatch({
        type: 'SET_STORE',
        payload: subsidiary,
      });
    }
  }, [dispatch, hasTimeslotsV1, hasTimeslotsV2, subsidiary]);

  return [
    getAppointments,
    {
      timeslotsData,
      loading: getLoading(loadingV1, loadingV2, errorV1, calledV2),
      error: getError(errorV1, errorV2),
    },
  ];
};

export default useLazyAppointmentSlots;

import { useLazyQuery } from '@apollo/client';
import {
  APPOINTMENT_INFORMATION_QUERY,
  APPOINTMENT_STORES_QUERY,
} from 'pages/Consultation/components/SubsidiarySearch/Appointment.query';
import { APPOINTMENT_AREA_QUERY } from 'pages/Consultation/components/Start/Start.query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import { PRODUCT_QUERY } from 'pages/ProductDetail/ProductDetail.query';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

const useSubsidiariesAndUpdateStore = () => {
  const { state, dispatch } = useContext(ConsultationContext);

  const [loadStores, { data: storeData, loading, error, refetch }] =
    useLazyQuery(APPOINTMENT_STORES_QUERY);
  const [loadInformation, { data: informationData, error: informationError }] = useLazyQuery(
    APPOINTMENT_INFORMATION_QUERY,
  );

  const [loadProduct, { data: productData }] = useLazyQuery(PRODUCT_QUERY);
  const [loadArea, { data: areaData }] = useLazyQuery(APPOINTMENT_AREA_QUERY);

  const [zipCodeError, setZipCodeError] = useState(null);

  const { area, timendoMasterId, externId, areaId } = state || {};

  /**
   * Refetches and searchs the subsidiaries with given postalCode
   */
  const refetchSubsidiariesWithZip = useCallback(
    (postalCode) => {
      refetch({
        areaId: area?.externId || externId,
        postalCode,
      }).then(() => {
        setZipCodeError(null);
      });
    },
    [refetch, externId, area],
  );

  /**
   * If the area is available or timendoMasterId from PDP, load the stores.
   * Could be that area is null, because just an areaId is given via url-param or component-property
   */
  useEffect(() => {
    if (area || timendoMasterId) {
      const variables = {
        // Backend just accepts the externId for the areaId.
        areaId: area?.externId || timendoMasterId,
      };
      const location = local.getItem('location');
      if (location?.userLocation) {
        variables.postalCode = location.userLocation?.zipCode;
      }
      loadStores({
        variables,
      });
    }
  }, [loadStores, externId, area, timendoMasterId]);

  /**
   * Load areas data, if area is not set and timendoMasterId in state.
   */
  useEffect(() => {
    if (!area && timendoMasterId) {
      loadArea({ variables: {} });

      if (areaData) {
        const { getAppointmentAreas: { areas } = { areas: [] } } = areaData || {};
        if (!isArrayEmpty(areas)) {
          const areaInfo = areas.find((element) => element.externId === timendoMasterId);
          if (areaInfo) {
            dispatch({
              type: 'SET_AREA',
              payload: areaInfo,
            });
          }
        }
      }
    }
  }, [area, areaData, dispatch, loadArea, timendoMasterId]);

  /**
   * Load product-data, if product is not set and productCode in state.
   */
  useEffect(() => {
    if (state.productCode && !productData) {
      loadProduct({
        variables: {
          productCode: state.productCode,
        },
      });
    }
    if (state.productCode && productData) {
      const { getProduct: { name } = { name: null } } = productData;
      if (name) {
        dispatch({
          type: 'SET_PRODUCT',
          payload: name,
        });
      }
    }
  }, [state.productCode, loadProduct, productData, dispatch]);

  /**
   * Load area-information, if area is not set.
   * If the area is null, the effect will be called twice:
   * 1. for executing loadInformation
   * 2. for listening to informationData and setting the area
   */
  useEffect(() => {
    if (areaId && !area && !informationData) {
      loadInformation({
        variables: {
          areaId,
        },
      });
    }
    if (areaId && !area && informationData?.getAppointmentInformation) {
      const { getAppointmentInformation: { areaInformation } = { areaInformation: null } } =
        informationData;

      if (areaInformation) {
        dispatch({
          type: 'SET_AREA',
          payload: areaInformation,
        });
      }
    }
  }, [areaId, area, informationData, loadInformation, dispatch]);

  const { getAppointmentStores: { pointOfServices } = { pointOfServices: [] } } = storeData || {};

  return {
    subsidiaries: pointOfServices,
    refetchSubsidiariesWithZip,
    loading,
    error: error || informationError,
    zipCodeError,
  };
};

export default useSubsidiariesAndUpdateStore;

import { useCallback } from 'react';

import { getFirstErrorField } from 'utils/form';
import useMessage from 'components/Message/useMessage';
import useValidatePhoneNumber from 'pages/Checkout/components/AddressForms/SelectCountryWithFlag/useValidatePhoneNumber';

export const usePhoneNumberField = (
  setFirstErrorField?: (field: string) => void,
  fieldsOrder?: string[],
) => {
  const [errorMessage, notProvidedMessage] = useMessage([
    'phoneNumber.validation.invalid.number',
    'phoneNumber.validation.notProvided.number',
  ]);
  const { validatePhoneNumberMutation } = useValidatePhoneNumber();

  const validateOnPhoneBlur = useCallback(
    async ({
      phoneNumber,
      countryCode,
      formApi,
    }: {
      phoneNumber: string;
      countryCode: string;
      formApi: any;
    }): Promise<void> => {
      const phoneNumberValue = formApi.getValue(phoneNumber);
      const countryCodeValue = formApi.getValue(countryCode);

      if (!phoneNumberValue || !countryCodeValue) {
        setFirstErrorField?.('');
        return;
      }

      const results = await validatePhoneNumberMutation({
        countryIso: countryCodeValue,
        phoneNumber: phoneNumberValue,
      });

      if (results.loading) {
        // Weird scenario when we don't wait - we want to ignore it.
        return;
      }

      if (results?.data?.isValid) {
        formApi.setError(phoneNumber, '');
        formApi.setValue(countryCode, results.data.phoneNumberCountry);
        formApi.setValue(phoneNumber, results.data.locallyFormattedNumber);
        formApi.setValue(
          'phoneNumberInternationalHidden',
          results.data.internationallyFormattedNumber,
        );
        setFirstErrorField?.('');
      }

      if (!results?.data?.isValid) {
        if (results?.data?.isPossible) {
          formApi?.setError(phoneNumber, notProvidedMessage);
        } else {
          formApi?.setError(phoneNumber, errorMessage);
        }

        if (setFirstErrorField) {
          const field = getFirstErrorField(fieldsOrder, formApi?.getState().errors);
          setFirstErrorField(field);
        }
      }
    },
    [
      errorMessage,
      fieldsOrder,
      notProvidedMessage,
      setFirstErrorField,
      validatePhoneNumberMutation,
    ],
  );

  return {
    validateOnPhoneBlur,
  };
};

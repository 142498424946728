export const getAreaName = (name) => name.replace('Fachberatung für Ihren ', '');

export const getTrackingAreaData = ({ id, publicName, length }) => ({
  id,
  name: getAreaName(publicName),
  duration: `${length} min`,
});

export const getTrackingStartData = (area) => ({
  id: area.id,
  area: getTrackingAreaData(area),
});

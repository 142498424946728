import React, { useContext } from 'react';
import track from 'react-tracking';
import { Icon, Heading } from '@xxxlgroup/hydra-ui-components';
import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { store, reserve } from '@xxxlgroup/hydra-icons';
import Layout from 'components/Layout';
import Message from 'components/Message';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import styles from 'pages/Consultation/components/Summary/Summary.scss';
import parseTimeslotDate from 'pages/Consultation/utils/parseTimeslotDate';
import { tagComponent } from 'utils/tracking/tracking';
import getAddress from 'pages/Consultation/utils/getAddress';
import useDayjsUtc from 'hooks/useDayjsUtc';

const Summary = React.memo(() => {
  const { state } = useContext(ConsultationContext);
  const { store: location, area, timeslot } = state;
  const dayjsUtc = useDayjsUtc();

  const renderAddress = () => {
    const { name, address } = location;

    if (name && address) {
      return (
        <div className={styles.column}>
          <Icon glyph={store} className={styles.icon} aria-hidden="true" />
          <div>
            <dt className={styles.title}>{name}</dt>
            <dd className={styles.content}>{getAddress(address)}</dd>
          </div>
        </div>
      );
    }
    return null;
  };

  if (isObjectEmpty(area)) {
    return null;
  }

  return (
    <Layout className={styles.contentWrapper} margin="none">
      <Heading className={styles.heading} level={3}>
        {area.publicName}
      </Heading>
      <dl className={styles.columnGrid}>
        {renderAddress()}
        {timeslot && timeslot.start && (
          <div className={styles.column}>
            <Icon glyph={reserve} className={styles.icon} aria-hidden="true" />
            <div>
              <dt className={styles.title}>
                <Message
                  code="consultation.header.dateInformation"
                  values={{
                    date: parseTimeslotDate(timeslot, dayjsUtc).format('DD.MM.YYYY'),
                    time: parseTimeslotDate(timeslot, dayjsUtc).format('HH:mm'),
                  }}
                />
              </dt>
              {area.length && (
                <dd className={styles.content}>
                  <Message code="consultation.header.length" values={{ length: area.length }} />
                </dd>
              )}
            </div>
          </div>
        )}
      </dl>
    </Layout>
  );
});

export default track(tagComponent('Summary'))(Summary);

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '@xxxlgroup/hydra-ui-components';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';

import styles from 'pages/Consultation/components/ConsultationBreadcrumbs/ConsultationBreadcrumbs.scss';
import Message from 'components/Message';

const indexes = {
  start: { idx: 0, back: 'BACK_TO_START', code: 'consultation.breadcrumb.area' },
  search: { idx: 1, back: 'BACK_TO_SEARCH', code: 'consultation.breadcrumb.subsidiarySearch' },
  month: { idx: 2, back: 'BACK_TO_MONTH', code: 'consultation.breadcrumb.month' },
  day: { idx: 3, back: 'BACK_TO_DAY', code: 'consultation.breadcrumb.day' },
  register: { idx: 4, back: 'BACK_TO_REGISTER', code: 'consultation.breadcrumb.register' },
};

const ConsultationBreadcrumbs = () => {
  const { state, dispatch } = useContext(ConsultationContext);
  const { activeView, baseView } = state;
  const [active, setActive] = useState(indexes[activeView]);
  const isMobile = useMediaQuery({ smallerThan: 'md' });

  const breadcrumbsItems = useMemo(() => {
    const items = [];
    const renderBreadcrumbItem = (type, code, activeLink) => {
      const attr = {
        key: type,
        name: code,
      };
      if (!activeLink) {
        attr.onClick = () => dispatch({ type });
      }
      return (
        <BreadcrumbsItem {...attr}>
          <Message code={code} />
        </BreadcrumbsItem>
      );
    };

    // Lets build the array of all breadcrumb-items
    Object.keys(indexes).forEach((page) => {
      const idx = indexes[page];
      if (idx.idx <= active.idx) {
        items.push(renderBreadcrumbItem(idx.back, idx.code, idx.idx === active.idx));
      }
    });

    // In case the url contains /areaId or /interventionId/groupId we have to cut the breadcrumb,
    // depending on baseView, we either have to delete 1 or 2 items from beginning.
    let deleteCount = 0;
    if (baseView === 'search') {
      deleteCount = 1;
    } else if (baseView === 'month') {
      deleteCount = 2;
    }
    items.splice(0, deleteCount);

    // In the mobile view, we want to display the back-link, NOT the current/active page (what is included in the breadcrumbs)
    // so lets pop it out
    if (isMobile) {
      items.pop();
    }

    return items;
  }, [active.idx, baseView, dispatch, isMobile]);

  useEffect(() => {
    setActive(indexes[activeView]);
  }, [activeView]);

  // if on Desktop the breadcrumbs-length is one, we don't wanna display it. On mobile we show a single breadcrumb (basically the back button)
  return (breadcrumbsItems.length === 1 && !isMobile) || breadcrumbsItems.length === 0 ? null : (
    <Breadcrumbs className={styles.breadcrumbs}>{breadcrumbsItems}</Breadcrumbs>
  );
};

export default ConsultationBreadcrumbs;

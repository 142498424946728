import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import track from 'react-tracking';
import { useHistory } from 'react-router-dom';

import { Button, Content, FeedbackCard, Skeleton } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import Layout from 'components/Layout';
import Message from 'components/Message';

import useRoutes from 'hooks/useRoutes';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import CompletionMessage from 'pages/Consultation/components/CompletionMessage';
import { APPOINTMENT_INFORMATION_QUERY } from 'pages/Consultation/components/SubsidiarySearch/Appointment.query';
import { CANCEL_APPOINTMENT } from 'pages/Consultation/components/CancelInquiry/CancelMutation';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';

import styles from 'pages/Consultation/components/CancelInquiry/CancelInquiry.scss';
import SummaryConfirmation from 'pages/Consultation/components/SummaryConfirmation';

const CancelInquiry = (props) => {
  const { state, dispatch } = useContext(ConsultationContext);
  const { appointmentId, storeId } = state;
  const routes = useRoutes();
  const [cancelAppointment, { data = {}, error, loading }] = useMutation(CANCEL_APPOINTMENT);
  const { data: dataArea, loading: loadingQuery } = useQuery(APPOINTMENT_INFORMATION_QUERY, {
    skip: !appointmentId || !storeId,
    variables: {
      appointmentId,
      storeId,
    },
  });

  const [cancelErrors, setCancelErrors] = useState([]);
  const history = useHistory();
  const tracking = useTracking(props, 'CancelInquiry');

  useEffect(() => {
    if (dataArea?.getAppointmentInformation) {
      dispatch({
        type: 'SET_CANCEL_APPOINTMENT',
        payload: dataArea.getAppointmentInformation,
      });
    }
  }, [dataArea, dispatch]);

  const getCancellingData = () => ({
    data: {
      appointment: { id: state.appointmentId },
      store: { timendoGroupId: state.store.timendoGroupId },
      area: { id: state.area.id },
    },
  });

  const handleBackButton = useCallback(
    (event) => {
      const trackingData = { area: state.trackingData };
      tracking(event, trackingData);

      if (appointmentId && storeId) {
        dispatch({
          type: 'RESET_STATE',
        });
        history.push(routes.homePage);
        return;
      }

      dispatch({
        type: 'SET_ACTIVE_VIEW',
        payload: 'finish',
      });
    },
    [appointmentId, dispatch, history, routes.homePage, state.trackingData, storeId, tracking],
  );

  const handleAppointmentCancelling = (event) => {
    const cancellingData = getCancellingData();
    const trackingData = { ...cancellingData, area: state.trackingData };
    tracking(event, trackingData);

    cancelAppointment({
      fetchPolicy: 'no-cache',
      variables: cancellingData,
    });
  };

  useEffect(() => {
    if (!loading && error) {
      const { errors } = error.graphQLErrors[0].extensions;
      if (!isArrayEmpty(errors)) {
        setCancelErrors(errors[0]?.extensions.errors);
      }
    }
  }, [loading, error]);

  if (data?.cancelAppointment) {
    dispatch({
      type: 'SET_ACTIVE_VIEW',
      payload: 'cancelConfirmed',
    });
  }

  return (
    <Layout variant="narrow" className={styles.cancelInquiry}>
      <CompletionMessage />

      {!isArrayEmpty(cancelErrors) &&
        cancelErrors.map((cancelError) => (
          <FeedbackCard key={cancelError.message} variant="error" className={styles.feedbackCard}>
            <Content content={cancelError.message} />
          </FeedbackCard>
        ))}
      {!loadingQuery && state.store.name ? (
        <SummaryConfirmation />
      ) : (
        <Skeleton className={styles.skeleton} />
      )}

      <Layout variant="full" margin="none" className={styles.buttonsWrapper}>
        <Layout variant="full" margin="none" grid="grid-2" className={styles.buttonsColumn}>
          <Button
            data-purpose="consultation.cancel.back.button"
            onClick={handleBackButton}
            variant="tertiary"
          >
            <Message code="consultation.cancel.back" />
          </Button>
          <Button
            data-purpose="consultation.cancel.confirm.button"
            disabled={loadingQuery || !state.store.name}
            onClick={handleAppointmentCancelling}
            loading={loading}
            variant="primary"
          >
            <Message code="consultation.cancel.confirm" />
          </Button>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default track(tagComponent('CancelInquiry'))(CancelInquiry);

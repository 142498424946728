import React, { useContext } from 'react';
import classnames from 'classnames';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import Layout from 'components/Layout';
import Message from 'components/Message';
import styles from 'pages/Consultation/components/CompletionMessage/CompletionMessage.scss';
import { Heading, Content, Icon } from '@xxxlgroup/hydra-ui-components';
import { circleCheckmark } from '@xxxlgroup/hydra-icons';
import parseTimeslotDate from 'pages/Consultation/utils/parseTimeslotDate';
import useDayjsUtc from 'hooks/useDayjsUtc';

const CompletionMessage = () => {
  const { state } = useContext(ConsultationContext);
  const dayjsUtc = useDayjsUtc();

  const getContent = () => {
    switch (state.activeView) {
      case 'finish':
        return {
          title: 'consultation.finish.title',
          description: 'consultation.finish.message',
        };
      case 'cancelInquiry':
        return {
          title: 'consultation.cancel.title',
          description: 'consultation.cancel.message',
        };
      case 'cancelConfirmed':
      default:
        return {
          title: 'consultation.confirmCancel.title',
          description: 'consultation.confirmCancel.message',
        };
    }
  };

  return (
    <Layout
      variant="full"
      margin="content"
      className={classnames(
        styles.completionMessage,
        state.activeView === 'cancelInquiry' && styles.cancelInquiry,
      )}
    >
      <div className={styles.iconWrapper}>
        {['finish', 'cancelConfirmed'].includes(state.activeView) && (
          <Icon glyph={circleCheckmark} className={styles.icon} />
        )}
      </div>
      <div>
        <Heading
          content={
            <Message
              code={getContent().title}
              values={
                state.activeView === 'cancelConfirmed' && {
                  day: parseTimeslotDate(state.timeslot, dayjsUtc).format('dddd'),
                  date: parseTimeslotDate(state.timeslot, dayjsUtc).format('DD. MMMM YYYY'),
                }
              }
            />
          }
          className={styles.heading}
          level={2}
        />
        <Content
          content={<Message code={getContent().description} />}
          key="completionDescription"
        />
      </div>
    </Layout>
  );
};
export default CompletionMessage;

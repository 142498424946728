import React, { useEffect, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import track from 'react-tracking';
import useRoutes from 'hooks/useRoutes';
import Helmet from 'components/Helmet';
import {
  ConsultationContext,
  consultationReducer,
  initConsultationReducer,
} from 'pages/Consultation/Consultation.state';
import Start from 'pages/Consultation/components/Start';
import Finish from 'pages/Consultation/components/Finish';
import CancelInquiry from 'pages/Consultation/components/CancelInquiry';
import CancelConfirmed from 'pages/Consultation/components/CancelConfirmed';
import styles from 'pages/Consultation/Consultation.scss';
import { useRouteMatch } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

import TimeBlocks from 'pages/Consultation/components/TimeBlocks';
import SubsidiarySearch from 'pages/Consultation/components/SubsidiarySearch';
import Register from 'pages/Consultation/components/Register';
import Summary from 'pages/Consultation/components/Summary';
import ErrorInfoCard from 'pages/Consultation/components/ErrorInfoCard';
import { getHostFromWhitelist } from 'pages/Consultation/utils/iframe';

import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import { tagComponent } from 'utils/tracking/tracking';
import MediaQuery from 'components/MediaQuery';
import ConsultationBreadcrumbs from 'pages/Consultation/components/ConsultationBreadcrumbs';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import useResizeDomElement from 'hooks/useResizeDomElement';
import useIsModeHeadless from 'hooks/useIsModeHeadless';

const Consultation = ({
  areaId,
  timendoMasterId,
  appointmentId,
  interventionId,
  groupId,
  storeId,
  productCode,
  hasMetaTags,
  headlineSeo,
}) => {
  const routes = useRoutes();
  const [state, dispatch] = useReducer(
    consultationReducer,
    {
      areaId,
      timendoMasterId,
      appointmentId,
      interventionId,
      groupId,
      storeId,
      productCode,
      headlineSeo,
    },
    initConsultationReducer,
  );
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const params = useParams();

  const isMountedOnCSR = useIsMountedOnCSR();
  const isModeHeadless = useIsModeHeadless();

  // Handle the resize event
  const onResize = useCallback(
    (target) => {
      if (isMountedOnCSR && isModeHeadless) {
        const targetOrigin = getHostFromWhitelist();
        if (targetOrigin) {
          const height = target.clientHeight + 10;
          window.parent.postMessage({ event: 'resizeConsultationIFrame', height }, targetOrigin);
        }
      }
    },
    [isModeHeadless, isMountedOnCSR],
  );

  const consultationRef = useResizeDomElement(onResize);

  const renderView = () => {
    switch (state.activeView) {
      case 'finish':
        return <Finish />;
      case 'search':
        return <SubsidiarySearch />;
      case 'month':
      case 'day':
        return <TimeBlocks />;
      case 'register':
        return (
          <MediaQuery smallerThan="lg">
            {(isMobileLayout) => <Register isMobileLayout={isMobileLayout} />}
          </MediaQuery>
        );
      case 'cancelInquiry':
        return <CancelInquiry />;
      case 'cancelConfirmed':
        return <CancelConfirmed />;
      case 'start':
      default:
        return <Start />;
    }
  };

  const scrollToRef = useCallback(() => {
    if (consultationRef.current) {
      consultationRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [consultationRef]);

  useEffect(() => {
    if (state.activeView !== state.baseView || state.scrollToConsultation) {
      scrollToRef();
    }
  }, [scrollToRef, state.activeView, state.baseView, state.scrollToConsultation]);

  return (
    <div
      ref={consultationRef}
      className={classnames({
        [styles.inCmsPage]: !params.cmsPageCode,
        [styles.inIframe]: isModeHeadless,
      })}
      data-testid="consultation.area"
    >
      {!hasMetaTags && <Helmet title={routes.timendoPage.name} noIndex noFollow />}
      <ConsultationContext.Provider value={contextValue}>
        {!['start', 'finish', 'cancelInquiry', 'cancelConfirmed'].includes(state.activeView) && (
          <Layout
            className={classnames(
              styles.consultationGrid,
              styles.largeGrid,
              isModeHeadless ? styles.summaryIframe : styles.summaryWrapper,
            )}
            margin="none"
            data-testid="consultation.summaryWrapper"
          >
            <div>
              <ConsultationBreadcrumbs />
              <Summary />
            </div>
          </Layout>
        )}
        <Layout
          className={classnames(
            styles.consultationGrid,
            ['start', 'finish', 'cancelInquiry', 'cancelConfirmed'].includes(state.activeView)
              ? styles.largeGrid
              : styles.smallGrid,
          )}
          margin="none"
        >
          <ErrorBoundary
            errorChildren={<ErrorInfoCard />}
            className={styles.errorBoundaryCard}
            withStatusCode
          >
            {renderView()}
          </ErrorBoundary>
        </Layout>
      </ConsultationContext.Provider>
    </div>
  );
};

Consultation.propTypes = {
  areaId: PropTypes.string,
  timendoMasterId: PropTypes.string,
  appointmentId: PropTypes.string,
  interventionId: PropTypes.string,
  groupId: PropTypes.string,
  storeId: PropTypes.string,
  productCode: PropTypes.string,
  /* show meta information noIndex, noFollow  */
  hasMetaTags: PropTypes.bool,
  headlineSeo: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
};

Consultation.defaultProps = {
  areaId: null,
  timendoMasterId: null,
  appointmentId: null,
  interventionId: null,
  groupId: null,
  storeId: null,
  productCode: null,
  hasMetaTags: false,
  headlineSeo: 'h1',
};

/*
 * We must allow that either areaId OR interventionId + groupId is given.
 * So if just one param is given, its an areaId, example: /consultation/123
 * if two params are given, its an interventionId + groupId, example: /consultation/456/789
 */
const ConsultationRouter = (props) => {
  const { path } = useRouteMatch();

  const matchArea = useRouteMatch({
    path: `${path}/:areaId`,
    strict: true,
    exact: true,
  });

  const matchProduct = useRouteMatch({
    path: `${path}/:timendoMasterId/p/:productCode`,
    strict: true,
    exact: true,
  });

  const matchIntervention = useRouteMatch({
    path: `${path}/:interventionId/:groupId`,
    strict: true,
    exact: true,
  });

  const useQuery = () =>
    // eslint-disable-next-line compat/compat
    new URLSearchParams(useLocation().search);
  const query = useQuery();
  const appointmentId = query.get('appointmentId');
  const storeId = query.get('storeId');

  // to cancel an appointment from the email url: beratungstermin?appointmentId=407799860&storeId=106195
  if (appointmentId && storeId) {
    return <Consultation {...props} appointmentId={appointmentId} storeId={storeId} />;
  }

  // to go to subsidiaries page from product page
  if (matchProduct) {
    return (
      <Consultation
        {...props}
        timendoMasterId={matchProduct.params.timendoMasterId}
        productCode={matchProduct.params.productCode}
      />
    );
  }

  if (matchArea) {
    return <Consultation {...props} areaId={matchArea.params.areaId} />;
  }

  if (matchIntervention) {
    return (
      <Consultation
        {...props}
        interventionId={matchIntervention.params.interventionId}
        groupId={matchIntervention.params.groupId}
      />
    );
  }

  return <Consultation {...props} />;
};

export default track(tagComponent('ConsultationRouter'))(ConsultationRouter);

import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { chevronRight, fourSquaresLoading, info } from '@xxxlgroup/hydra-icons';
import { Content, Icon } from '@xxxlgroup/hydra-ui-components';
import Layout from 'components/Layout';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'pages/Consultation/components/SubsidiarySearch/components/Subsidiaries/components/Subsidiary/Subsidiary.scss';
import getAddress from 'pages/Consultation/utils/getAddress';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import Message from 'components/Message';
import useLazyAppointmentSlots from 'pages/Consultation/components/SubsidiarySearch/useLazyAppointmentSlots';
import classnames from 'classnames';
import { ReactTypes } from 'types';

const Subsidiary = (props) => {
  const { subsidiary, element } = props;
  const [getAppointments, { timeslotsData, loading }] = useLazyAppointmentSlots(subsidiary);
  const tracking = useTracking(props, 'Subsidiary');
  const { name, address, openingHoursText, timendoGroupId, timendoInterventionId } = subsidiary;
  const { state } = useContext(ConsultationContext);

  const trackingData = useMemo(
    () => ({
      id: timendoGroupId,
      timendoInterventionId,
      name,
      address,
      openingHours: openingHoursText,
      area: state.trackingData,
    }),
    [address, name, openingHoursText, state.trackingData, timendoGroupId, timendoInterventionId],
  );

  const handleClick = useCallback(
    (event) => {
      tracking(event, trackingData);
      getAppointments();
    },
    [getAppointments, tracking, trackingData],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleClick(event);
      }
    },
    [handleClick],
  );
  const noAvailableTimeSlots =
    timeslotsData && isArrayEmpty(timeslotsData.getAppointmentTimeslots?.availableTimeslots);

  return (
    <Layout
      forwardedRef={element}
      variant="narrow"
      className={classnames(styles.container, { [styles.containerLoading]: loading })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
      data-purpose="consultation.subsidiary"
      {...props}
    >
      {loading && <Icon glyph={fourSquaresLoading} className={styles.iconLoading} />}
      <div
        className={classnames(styles.subsidiary, {
          [styles.subsidiaryInactive]: noAvailableTimeSlots,
          [styles.subsidiaryLoading]: loading,
        })}
      >
        <Icon glyph={chevronRight} className={styles.icon} />
        <Content className={styles.heading} content={subsidiary.name} />
        <Content className={styles.subHeading} content={getAddress(subsidiary.address)} />
      </div>
      {noAvailableTimeSlots && (
        <div className={styles.infoContainer}>
          <Icon glyph={info} className={styles.iconInfo} />
          <Message
            code="consultation.info.noFreeTimeSlots"
            values={{ subsidiaryName: subsidiary.name }}
          >
            {(consultationInfo) => (
              <Content className={styles.noTimeSlotsInfo} content={consultationInfo} role="alert" />
            )}
          </Message>
        </div>
      )}
    </Layout>
  );
};

Subsidiary.propTypes = {
  /** the subsidiary data */
  subsidiary: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      streetname: PropTypes.string,
      streetnumber: PropTypes.string,
      postalCode: PropTypes.string,
      town: PropTypes.string,
    }).isRequired,
    /* the opening work Hours  */
    openingHoursText: PropTypes.string,
    /* the store id  */
    timendoGroupId: PropTypes.string.isRequired,
    /* the area id  */
    timendoInterventionId: PropTypes.string.isRequired,
  }).isRequired,
  /** ref for keyboard focus */
  element: ReactTypes.ref,
};

Subsidiary.defaultProps = {
  element: null,
};

export default track(tagComponent('Subsidiary'))(Subsidiary);

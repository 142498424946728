import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { FieldProps } from 'components/Form/components/Field/Field.types';
import React, { useMemo } from 'react';
import { Field } from 'components/Form';
import useMessage from 'components/Message/useMessage';
import { useInformedField } from 'hooks/useInformedFieldWrapper';

interface OptionalSelectFieldProps extends FieldProps {
  /** options of select */
  options: Record<string, string>[];
  /** message code used for the optional field */
  optionalMessageCode?: string;
  /** key of options-object which should be displayed as selected option */
  keyName?: string;
  /** key of options-object which should get returned on selection */
  keyValue?: string;
}

/**
 * This is a generic <OptionalSelectField /> component, which is used by MANY consumers,
 * which means do NOT ADD specific business implementation for your feature.
 * The change will be applied to all consumers and must be aligned with all of them.
 */
const OptionalSelectField: React.FC<OptionalSelectFieldProps> = ({
  isFirstError = false,
  isRequired = false,
  keyName = 'item',
  keyValue = 'value',
  name,
  options,
  optionalMessageCode = 'wxs.form.select.none',
  ...others
}) => {
  const { value } = useInformedField(name, 'fieldState');
  const none = useMessage(optionalMessageCode);

  const newOptions = useMemo(() => {
    if (!isArrayEmpty(options)) {
      const tempOptions = [...options];
      if (!isRequired) {
        tempOptions.unshift({ [keyName]: none, [keyValue]: '' });
      }
      return tempOptions;
    }
    return [];
  }, [options, isRequired, keyName, none, keyValue]);

  return (
    <Field
      keyName={keyName}
      keyValue={keyValue}
      options={newOptions}
      isRequired={isRequired}
      withOptionalSymbol
      isFirstError={isFirstError}
      type="select"
      selectedOption={value?.toString() || ''}
      name={name}
      {...others}
    />
  );
};

export default OptionalSelectField;

/* eslint-disable react/require-default-props */

import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { Content, Link as HydraLink, RangeSlider } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';

import { noop } from '@xxxlgroup/hydra-utils/common';
import Controls from 'pages/Consultation/components/TimeBlocks/components/Controls';
import styles from 'pages/Consultation/components/TimeBlocks/components/Month/Month.scss';
import Message from 'components/Message';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import ConsultationHeading from 'pages/Consultation/components/ConsultationHeading';
import useDayjs from 'hooks/useDayjs';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import RadioButton from 'pages/Consultation/components/TimeBlocks/components/RadioButton';
import classnames from 'classnames';
import useMessage from 'components/Message/useMessage';

const initialValues = [9, 19];

const Month = (props) => {
  const {
    currentMonth,
    currentYear,
    calendarData = [],
    onDateChange = noop,
    handleTimerangeChange = noop,
    onTimerangeAfterChange = noop,
    isLoading = false,
    timeRange: [valMin, valMax] = initialValues,
  } = props;
  const tracking = useTracking(props, 'Month');
  const { state } = useContext(ConsultationContext);
  const dayjs = useDayjs();

  const [selectedSlotKey, setSelectedSlotKey] = useState();
  const currentSelectedDay = useRef(null);
  const submitText = useMessage('accessibility.contactcenter.nextStep');

  const renderTitleRow = () => {
    const titles = [];
    for (let i = 0; i < 7; i += 1) {
      titles.push(dayjs().day(i).format('dd'));
    }
    titles.push(titles.shift()); // first day => last day

    return titles.map((dayOfWeek) => (
      <div className={styles.dayName} key={`dayTitle_${dayOfWeek}`}>
        {dayOfWeek}
      </div>
    ));
  };

  const handleOnDayClick = useCallback(
    (day) => (event) => {
      const data = { day, month: currentMonth, year: currentYear };
      tracking(event, { ...data, area: state.trackingData });
      onDateChange(data);
    },
    [currentMonth, currentYear, onDateChange, state.trackingData, tracking],
  );

  const onChangeCallback = useCallback(
    (day, key) => () => {
      setSelectedSlotKey(key);
      currentSelectedDay.current = day;
    },
    [],
  );

  const renderDayBoxes = () => {
    const month = dayjs().year(currentYear).month(currentMonth);

    const startOfMonthDay = month.date(1).day();

    return calendarData.map(({ id, available, day: slotDay }) => {
      const dayObject = month.set('date', slotDay + 1);
      const key = `${slotDay}${currentMonth}${currentYear}`;
      return (
        <div
          key={id}
          className={styles.container}
          style={
            // 0 is sunday => start column is 7, 1 monday, ...
            slotDay === 0 ? { gridColumnStart: startOfMonthDay === 0 ? 7 : startOfMonthDay } : {}
          }
        >
          <RadioButton
            ariaLabel={dayObject.format('DD. MMMM')}
            className={classnames(!available && styles.notAvailable)}
            isDisabled={!available}
            id={id}
            name="day"
            isChecked={selectedSlotKey === key}
            onKeyDown={handleOnDayClick(slotDay + 1)}
            onClick={handleOnDayClick(slotDay + 1)}
            onChange={onChangeCallback(slotDay, key)}
            data-purpose="consultation.month.dayTile"
          >
            <span>{dayObject.format('DD')}</span>
          </RadioButton>
        </div>
      );
    });
  };

  return (
    <>
      <Message
        key="controlInfo"
        code={['consultation.month.title', 'consultation.month.controlInfo']}
      >
        {([title, subTitle]) => <ConsultationHeading content={title} subContent={subTitle} />}
      </Message>
      <div className={styles.rangerSliderWrapper}>
        <div className={styles.startTime}>
          <Content content={`${valMin}:00`} size="sm-bold" />
        </div>
        <Message code={['consultation.month.slider.from', 'consultation.month.slider.to']}>
          {([fromMessage, toMessage]) => (
            <RangeSlider
              className={styles.rangeSlider}
              onChange={onTimerangeAfterChange}
              step={1}
              minLimit={initialValues[0]}
              maxLimit={initialValues[1]}
              minValue={valMin}
              maxValue={valMax}
              dispatchChange={handleTimerangeChange}
              i18n={{
                from: fromMessage,
                to: toMessage,
                unit: '',
              }}
            />
          )}
        </Message>
        <div className={styles.endTime}>
          <Content content={`${valMax}:00`} size="sm-bold" />
        </div>
      </div>
      <Controls
        period="month"
        year={currentYear}
        month={currentMonth}
        onChange={onDateChange}
        isLoading={isLoading}
      />
      <div className={styles.dates}>
        <div className={styles.dayNamesWrapper}>{renderTitleRow()}</div>
        <form>
          <div className={styles.weekRow}>{renderDayBoxes()}</div>
          <HydraLink
            onClick={(e) => {
              e.preventDefault();
              currentSelectedDay.current !== null &&
                handleOnDayClick(currentSelectedDay.current + 1)(e);
            }}
            className={styles.submit}
            type="submit"
            glyphAfter={arrowRight}
            aria-label="submit"
          >
            {submitText}
          </HydraLink>
        </form>
      </div>
    </>
  );
};

Month.propTypes = {
  /** array of days in current month, contains all available timeslots for each day */
  calendarData: PropTypes.arrayOf(PropTypes.shape),
  /** timeslots are shown for given currentMonth */
  currentMonth: PropTypes.number.isRequired,
  /** timeslots are shown for given currentYear */
  currentYear: PropTypes.number.isRequired,
  /** handle onChange event of RangeSlider to set state */
  handleTimerangeChange: PropTypes.func,
  /** if loading the data */
  isLoading: PropTypes.bool,
  /** callback if the day changed in calender */
  onDateChange: PropTypes.func,
  /** callback if the time-range-slider is changed */
  onTimerangeAfterChange: PropTypes.func,
  /** timeslots are filtered to given time-range */
  timeRange: PropTypes.arrayOf(PropTypes.number),
};

export default track(tagComponent('Month'))(Month);

import React, { useEffect, useState, useCallback, useContext } from 'react';
import track from 'react-tracking';
import Layout from 'components/Layout';
import ErrorMessage from 'components/ErrorMessage';
import Message from 'components/Message';
import Month from 'pages/Consultation/components/TimeBlocks/components/Month';

import Day from 'pages/Consultation/components/TimeBlocks/components/Day';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import parseTimeslotDate from 'pages/Consultation/utils/parseTimeslotDate';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import useLazyAppointmentSlots from 'pages/Consultation/components/SubsidiarySearch/useLazyAppointmentSlots';
import useDayjs from 'hooks/useDayjs';
import useDayjsUtc from 'hooks/useDayjsUtc';

const loadingMonthPlaceholder = () => {
  const month = [];
  for (let i = 0; i < 30; i += 1) {
    month.push({ id: i, day: i, timeslots: [], available: false });
  }
  return month;
};

const generateCalendarData = (
  currentMonth,
  currentYear,
  timeRange,
  timeslotsData,
  dayjs,
  dayjsUtc,
) => {
  const [startHour, endHour] = timeRange;

  const {
    getAppointmentTimeslots: { availableTimeslots = [] },
  } = timeslotsData;

  const timeslots = {};

  availableTimeslots.forEach((timeslot) => {
    const dateObj = parseTimeslotDate(timeslot, dayjsUtc);
    if (currentMonth !== dateObj.month()) {
      return;
    }
    const key = dateObj.date();
    const hour = dateObj.hour();

    const item = {
      ...timeslot,
      date: key,
      hour,
    };

    if (hour >= startHour && hour < endHour) {
      if (!timeslots[key]) {
        timeslots[key] = [];
      }

      timeslots[key].push(item);
    }
  });

  const month = dayjs().year(currentYear).month(currentMonth);

  const monthDayCount = month.endOf('month').date();

  // Create array with 30/31 entries and iterate over the month.
  // Reason: in timeslots days can be missing, so we can't iterate over them.
  return [...Array(monthDayCount).keys()].map((dayIndex) => {
    const timeslotData = timeslots[dayIndex + 1] || [];

    return {
      id: dayIndex,
      day: dayIndex,
      timeslots: timeslotData,
      available: !!timeslotData.length,
    };
  });
};

const findNextAvailableDay = (searchCalendarData, day) => {
  let countingDay = day;
  let nextDayCalendarData;
  while (!nextDayCalendarData && countingDay <= 31) {
    const nextData = searchCalendarData[countingDay];
    if (nextData && nextData.timeslots.length > 0) {
      nextDayCalendarData = nextData;
    }
    countingDay += 1;
  }
  return nextDayCalendarData;
};

const TimeBlocks = (props) => {
  const tracking = useTracking(props, 'TimeBlocks');
  const dayjsUtc = useDayjsUtc();
  const dayjs = useDayjs();
  const { state, dispatch } = useContext(ConsultationContext);
  const { date } = state;
  // If state.date is set, use this as currentDate, otherwise take current day.
  const [currentDate] = useState(
    !date ? dayjs() : dayjs(`${date.year}-${date.month + 1}-${date.day}`),
  );

  const [getAppointments, { timeslotsData, loading, error }] = useLazyAppointmentSlots(state.store);

  const [timeRange, setTimeRange] = useState([9, 19]);
  const [calendarData, setCalendarData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(currentDate.month());
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [currentDay, setCurrentDay] = useState(currentDate.date());

  useEffect(() => {
    getAppointments();
    if (!state.area && state.interventionId && timeslotsData) {
      const { getAppointmentInformation } = timeslotsData;
      const { areaInformation: area, storeInformation: store } = getAppointmentInformation;

      dispatch({
        type: 'SET_AREA_AND_STORE',
        payload: { area, store },
      });
    }
  }, [dispatch, getAppointments, state.area, state.interventionId, timeslotsData]);

  const generateAndSetCalendarData = useCallback(() => {
    const newCalendarData = generateCalendarData(
      currentMonth,
      currentYear,
      timeRange,
      timeslotsData,
      dayjs,
      dayjsUtc,
    );
    setCalendarData(newCalendarData);
  }, [currentMonth, currentYear, timeRange, timeslotsData, dayjs, dayjsUtc]);

  const handleFindNextAvailableDay = async (dayData) => {
    const nextDayCalendarData = findNextAvailableDay(calendarData, dayData.day);
    if (!nextDayCalendarData) {
      // no data found in current month, lets get data from next month
      const nextMonth = dayjs().month(currentMonth).year(currentYear).add(1, 'month');

      const nextMonthCalendarData = generateCalendarData(
        nextMonth.month(),
        nextMonth.year(),
        timeRange,
        timeslotsData,
        dayjs,
        dayjsUtc,
      );

      return findNextAvailableDay(nextMonthCalendarData, 0);
    }

    return nextDayCalendarData;
  };

  useEffect(() => {
    if (!loading && timeslotsData) {
      generateAndSetCalendarData();
    }
  }, [generateAndSetCalendarData, loading, timeslotsData]);

  const handleDateChanged = useCallback(
    ({ day = null, month, year }) => {
      setCurrentYear(year);
      setCurrentMonth(month);
      setCurrentDay(day);
      dispatch({
        type: 'SET_DATE',
        payload: { day, month, year },
      });
    },
    [dispatch],
  );

  const handleTimerangeChange = useCallback(
    (newTimeRange) => {
      const event = 'onChange';
      const data = {
        timeRange: newTimeRange,
        day: currentDay,
        month: currentMonth,
        year: currentYear,
        area: state.trackingData,
      };
      tracking(event, data);
      setTimeRange(newTimeRange);
    },
    [currentDay, currentMonth, currentYear, state.trackingData, tracking],
  );

  if (error) {
    return (
      <Layout variant="narrow" margin="none">
        <ErrorMessage
          uid={error.uid}
          message={<Message code="consultation.timeBlocks.error.loadingFailed" />}
        />
      </Layout>
    );
  }

  const calendarDataLoader = loading ? loadingMonthPlaceholder() : calendarData;

  return (
    <Layout variant="narrow" margin="none">
      {state.activeView === 'day' ? (
        <Day
          calendarData={calendarData}
          currentDay={currentDay}
          currentYear={currentYear}
          currentMonth={currentMonth}
          onDateChange={handleDateChanged}
          findNextAvailableDay={handleFindNextAvailableDay}
        />
      ) : (
        <Month
          handleTimerangeChange={handleTimerangeChange}
          onTimerangeAfterChange={generateAndSetCalendarData}
          calendarData={calendarDataLoader}
          onDateChange={handleDateChanged}
          currentDay={currentDay}
          currentYear={currentYear}
          currentMonth={currentMonth}
          timeRange={timeRange}
          isLoading={loading}
        />
      )}
    </Layout>
  );
};

export default track(tagComponent('TimeBlocks'))(TimeBlocks);

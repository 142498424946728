import { gql } from '@apollo/client';

export const APPOINTMENT_STORES_QUERY = gql`
  query appointmentStores($areaId: String!, $postalCode: String) {
    getAppointmentStores(areaId: $areaId, postalCode: $postalCode) {
      pointOfServices {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
    }
  }
`;

export const APPOINTMENT_INFORMATION_QUERY = gql`
  query getAppointmentInformation($areaId: String, $storeId: String, $appointmentId: String) {
    getAppointmentInformation(areaId: $areaId, storeId: $storeId, appointmentId: $appointmentId) {
      appointmentId
      start
      areaInformation {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
      storeInformation {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
      customer {
        email
        firstName
        gender
        lastName
        phone
      }
    }
  }
`;

import React, { FC, type ReactNode } from 'react';
import Layout from 'components/Layout';

import styles from 'components/ErrorMessage/ErrorMessage.scss';

interface ErrorMessageProps {
  /** Error message that will be shown after a redux error */
  message: ReactNode;
  /** Error UID */
  uid: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message, uid }) => (
  <Layout className={styles.wrapper} variant="full">
    <p>{message}</p>
    <p>{uid}</p>
  </Layout>
);

export default ErrorMessage;

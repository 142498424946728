/* eslint-disable @typescript-eslint/ban-types */
import { FieldApi, FieldState, FormValue, useFieldApi, useFieldState } from 'informed';

/** Informed library triggers a console.warn when a field is not rendered yet and we try to access it
    Informed does not provide a way to handle this better, so we rely on this workaround while discussions on replacing Informed library are ongoing
    (https://github.com/teslamotors/informed/issues/287#issuecomment-716029569)
 */

export type INFORMED_HOOKS = 'fieldApi' | 'fieldState';

type FieldApiOrState<T extends INFORMED_HOOKS> = T extends 'fieldState'
  ? FieldState<FormValue<{}>>
  : FieldApi<FormValue<{}>>;

export const useInformedField = <T extends INFORMED_HOOKS>(
  field: string,
  hookName: T,
): FieldApiOrState<typeof hookName> => {
  const { warn } = console;
  // Temporarily override console warn to remove false positive warning from useFieldState or useFieldApi
  const regex = /^Attempting to get field (.*) but it does not exist$/g;

  // eslint-disable-next-line no-console
  console.warn = (err) => {
    if (!err.match(regex)) {
      warn(err);
    }
  };

  const fieldApi = useFieldApi(field);
  const fieldStateApi = useFieldState(field);

  // eslint-disable-next-line no-console
  console.warn = warn;

  return (hookName === 'fieldState' ? fieldStateApi : fieldApi) as FieldApiOrState<typeof hookName>;
};

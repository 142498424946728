import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
  MutationValidatePhoneNumberArgs,
  ValidatedPhoneNumber,
} from 'graphql-types/generated/types-generated';
import { VALIDATE_PHONE_NUMBER_MUTATION } from 'pages/Checkout/Checkout.mutation';

type ValidatePhoneNumberResult = {
  data?: ValidatedPhoneNumber;
  errors: readonly GraphQLError[];
  loading: boolean;
};

const useValidatePhoneNumber = () => {
  const [validatePhoneNumber, { loading }] = useMutation<
    { validatePhoneNumber: ValidatedPhoneNumber },
    MutationValidatePhoneNumberArgs
  >(VALIDATE_PHONE_NUMBER_MUTATION);

  const validate = async ({
    countryIso,
    phoneNumber,
  }: MutationValidatePhoneNumberArgs): Promise<ValidatePhoneNumberResult> => {
    const results = await validatePhoneNumber({ variables: { countryIso, phoneNumber } });

    if (loading) {
      return { data: undefined, errors: [], loading };
    }

    if (results?.errors && !results?.data?.validatePhoneNumber) {
      return { data: undefined, errors: results.errors, loading };
    }

    return { data: results?.data?.validatePhoneNumber || undefined, errors: [], loading };
  };

  return { validatePhoneNumberMutation: validate, isValidatePhoneNumberLoading: loading };
};

export default useValidatePhoneNumber;

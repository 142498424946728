import { gql } from '@apollo/client';

export const APPOINTMENT_SLOTS_QUERY = gql`
  query getAppointmentTimeslots(
    $areaId: String!
    $storeId: String!
    $start: String!
    $end: String!
  ) {
    getAppointmentTimeslots(areaId: $areaId, storeId: $storeId, start: $start, end: $end) {
      availableTimeslots {
        calendarIds
        start
      }
    }
  }
`;

export const APPOINTMENT_SLOTS_WITH_INFO_QUERY = gql`
  query getAppointmentTimeslotsWithInfo(
    $areaId: String!
    $storeId: String!
    $start: String!
    $end: String!
  ) {
    getAppointmentTimeslots(areaId: $areaId, storeId: $storeId, start: $start, end: $end) {
      availableTimeslots {
        calendarIds
        start
      }
    }
    getAppointmentInformation(areaId: $areaId, storeId: $storeId) {
      areaInformation {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
      storeInformation {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
    }
  }
`;

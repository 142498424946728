import React, { useContext, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import track from 'react-tracking';
import {
  INPUT_LENGTH,
  validateEmail,
  validateMaxLength,
  validatePhoneNumber,
} from 'utils/validation';
import { Button, Content, FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import Layout from 'components/Layout';
import { Field, Form } from 'components/Form';
import useMessage from 'components/Message/useMessage';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { trimSpaces } from 'utils/formatting';
import { SUBMIT_APPOINTMENT_MUTATION } from 'pages/Consultation/components/Register/Register.mutation';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { usePhoneNumberField } from 'components/Form/hooks/usePhoneNumberField';
import { getErrorsFromGraphQLRequest } from 'utils/graphQL';
import PropTypes from 'prop-types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import ConsultationHeading from 'pages/Consultation/components/ConsultationHeading';
import GenderField from 'components/FormFields/GenderField/GenderField';

import useValidatePhoneNumber from 'pages/Checkout/components/AddressForms/SelectCountryWithFlag/useValidatePhoneNumber';
import useSubscribeNewsletter from 'components/NewsletterForm/useSubscribeNewsletter';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import styles from 'pages/Consultation/components/Register/Register.scss';

const Register = (props) => {
  const { isMobileLayout } = props;
  const { dispatch, state } = useContext(ConsultationContext);
  const { subscribeNewsletter } = useSubscribeNewsletter();
  const [isLastNameRequired, isFirstNameRequired, isNewsletterEnabled, isSocialPartnerEnabled] =
    useFeatureFlags([
      'poseidon.newsletter.lastname.enabled',
      'poseidon.newsletter.firstname.enabled',
      'poseidon.newsletter.enabled',
      'poseidon.consultation.socialPartners.enabled',
    ]);

  const [submitAppointment, { error, loading }] = useMutation(SUBMIT_APPOINTMENT_MUTATION, {
    onCompleted: (data) => {
      if (data?.postAppointment) {
        const { newsletterCheckbox, socialPartnersCheckbox } = state.form;
        if (newsletterCheckbox || socialPartnersCheckbox) {
          const newsletterData = {
            email: state.form.email,
            newsletter: !!state.form.newsletterCheckbox,
            agb: !!state.form.socialPartnersCheckbox,
            sourceId: 'TIMENDO_HYBRIS',
            timendoArea: state.area.name,
            ...(isFirstNameRequired && { firstName: state.form?.firstName }),
            ...(isLastNameRequired && { lastName: state.form?.lastName }),
          };
          subscribeNewsletter(newsletterData);
        }
        dispatch({
          type: 'SET_FINISHED_APPOINTMENT',
          payload: data.postAppointment.appointmentId,
        });
      }
    },
  });
  const debounceRef = useRef(null);
  const headerRef = useRef(null);
  const formApi = useRef(null);
  const tracking = useTracking(props, 'Register');
  const [
    textProduct,
    registerTitle,
    mandatoryText,
    phoneInfo,
    newsletterDescription,
    socialPartnersDescription,
    reminderText,
    submitText,
    errorMessage,
  ] = useMessage([
    'product.consultation.form.text',
    'consultation.register.title',
    'consultation.registerForm.mandatoryFieldsMessage',
    'consultation.registerForm.phoneInfo',
    'consultation.form.newsletterDescription',
    'consultation.form.socialPartnersDescription',
    'consultation.form.appointmentReminder.text',
    'consultation.form.submit',
    'phoneNumber.validation.invalid.number',
  ]);
  const isPhoneFieldWithSelectEnabled = useFeatureFlagsEnabled('google.phone.enabled');
  const isPhoneRequired = useFeatureFlagsEnabled(['poseidon.consultation.form.phone.required']);
  const { validatePhoneNumberMutation } = useValidatePhoneNumber();
  const { validateOnPhoneBlur } = usePhoneNumberField();

  const getTextProduct = useCallback(
    (comment) => {
      const { productCode, productName } = state;
      const newComment = comment || '';

      return productCode
        ? `${newComment} - ${textProduct}: ${productName}(${productCode})`
        : newComment;
    },
    [state, textProduct],
  );

  const setFormApi = (formApiArg) => {
    formApi.current = formApiArg;
  };

  const getSubmitData = useCallback(async () => {
    const { form, interventionId, timeslot } = state;
    let isPhoneValidated = !!form.phone;

    if (isPhoneFieldWithSelectEnabled) {
      if (form.phone !== undefined) {
        const results = await validatePhoneNumberMutation({
          countryIso: form.phoneNumberIsoCode,
          phoneNumber: form.phone,
        });

        isPhoneValidated = Boolean(results?.data?.isValid) && isArrayEmpty(results.errors);
        if (isPhoneValidated && !form.phoneNumberInternationalHidden) {
          form.phoneNumberInternationalHidden = results.data.internationallyFormattedNumber;
        }
      }
      form.phone = form.phoneNumberInternationalHidden;
      delete form.phoneNumberInternationalHidden;
    }

    if (isPhoneFieldWithSelectEnabled && !isPhoneValidated) {
      formApi.current?.setError('phone', errorMessage);
      return null;
    }

    const { gender, firstName, lastName, email, phone, bonusCard, comment, newsletterCheckbox } =
      form;

    return {
      data: {
        comment: getTextProduct(comment),
        customer: {
          email,
          firstName,
          gender,
          lastName,
          phone,
          newsletterAccepted: !!newsletterCheckbox,
        },
        date: timeslot.start,
        groupId: state.store.timendoGroupId,
        interventionIds: [interventionId || state.store.timendoInterventionId],
        bonusCard,
      },
    };
  }, [
    errorMessage,
    getTextProduct,
    isPhoneFieldWithSelectEnabled,
    state,
    validatePhoneNumberMutation,
  ]);

  const handleClick = useCallback(
    (event) => {
      const trackingData = { area: state.trackingData };
      tracking(event, trackingData);
    },
    [state.trackingData, tracking],
  );

  const handleHover = useCallback(
    (event) => {
      const trackingData = { area: state.trackingData };
      tracking(event, trackingData);
    },
    [state.trackingData, tracking],
  );

  const handleValueChange = useCallback(
    (values) => {
      dispatch({ type: 'UPDATE_FORM', payload: values });
    },
    [dispatch],
  );

  const handleSubmit = useCallback(async () => {
    const submitData = await getSubmitData();
    const isPhoneInPlace = submitData?.data?.customer?.phone;

    dispatch({
      type: 'SET_SUBMIT_ERRORS',
      payload: [],
    });

    isPhoneInPlace &&
      (await submitAppointment({
        fetchPolicy: 'no-cache',
        variables: submitData,
      }));
  }, [dispatch, getSubmitData, submitAppointment]);

  const handleValidation = () => {
    validateOnPhoneBlur({
      phoneNumber: 'phone',
      countryCode: 'phoneNumberIsoCode',
      formApi: formApi.current,
    });
  };

  useEffect(() => {
    // Add target _blank to links that come from translations.
    // We need to open links pages in new tabs if the user clicks on them also we use this page on iframe
    const links = document.querySelectorAll('#register_page a');
    links.forEach((link) => {
      link.setAttribute('target', '_blank');
    });
  }, []);

  useEffect(() => {
    if (!loading && error) {
      const [validationErrors, requestErrors] = getErrorsFromGraphQLRequest(error);

      if (!isArrayEmpty(validationErrors)) {
        validationErrors.forEach((validationError) => {
          const subject = validationError.subject.replace('customer.', '');
          formApi?.current?.setError(subject, validationError.message);
        });
      }

      if (!isArrayEmpty(requestErrors)) {
        dispatch({
          type: 'SET_SUBMIT_ERRORS',
          payload: requestErrors,
        });
        isMobileLayout && window.scrollTo(0, headerRef.current.offsetTop - 10);
      }
    }
  }, [loading, error, formApi, dispatch, isMobileLayout]);

  useEffect(() => {
    if (!isArrayEmpty(state.submitErrors) && headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state.submitErrors]);

  return (
    <Layout variant="narrow" margin="none" id="register_page">
      <div ref={headerRef}>
        <ConsultationHeading content={registerTitle} />
      </div>
      {!isArrayEmpty(state.submitErrors) && (
        <>
          {state.submitErrors.map((submitError) => (
            <FeedbackCard variant="error" key={submitError.message}>
              <Content content={submitError.message} />
            </FeedbackCard>
          ))}
        </>
      )}
      <Content content={mandatoryText} className={styles.mandatoryFieldsMessage} />
      <Form
        className={styles.form}
        data-purpose="consultation.register.form"
        getApi={setFormApi}
        onValueChange={handleValueChange}
        onSubmit={handleSubmit}
      >
        <GenderField
          isRequired={false}
          isValueTranslated
          className={classnames(styles.field, styles.gender)}
        />
        <Field
          autoComplete="given-name"
          name="firstName"
          className={classnames(styles.field, styles.firstName)}
          isRequired
          labelCode="wxs.form.input.firstName.label"
          placeholderCode="wxs.form.input.firstName.placeholder"
          validators={[validateMaxLength(INPUT_LENGTH.SINGLE_LINE)]}
        />
        <Field
          autoComplete="family-name"
          name="lastName"
          className={classnames(styles.field, styles.lastName)}
          isRequired
          labelCode="form.input.lastName.label"
          placeholderCode="form.input.lastName.placeholder"
          validators={[validateMaxLength(INPUT_LENGTH.SINGLE_LINE)]}
        />
        <Field
          autoComplete="email"
          name="email"
          className={classnames(styles.field, styles.email)}
          labelCode="wxs.form.input.email.label"
          placeholderCode="wxs.form.input.email.placeholder"
          isRequired
          inputMode="email"
          validators={[validateEmail()]}
        />
        {!isPhoneFieldWithSelectEnabled ? (
          <Field
            autoComplete="tel"
            name="phone"
            className={classnames(styles.field, styles.phone)}
            labelCode="consultation.form.phone.label"
            placeholderCode="consultation.form.phone.placeholder"
            isRequired={isPhoneRequired}
            validators={[validatePhoneNumber()]}
            inputMode="tel"
            description={<Content content={phoneInfo} className={styles.phoneDescription} />}
          />
        ) : (
          <Field
            autoComplete="tel"
            name="phone"
            className={classnames(styles.field, styles.phone)}
            labelCode="consultation.form.phone.label"
            placeholderCode="consultation.form.phone.placeholder"
            isRequired={isPhoneRequired}
            formatters={[trimSpaces]}
            inputMode="tel"
            description={<Content content={phoneInfo} className={styles.phoneDescription} />}
            onBlur={() => handleValidation()}
            onChange={() => {
              if (debounceRef.current) {
                clearTimeout(debounceRef.current);
              }
              debounceRef.current = setTimeout(() => handleValidation(), 800);
            }}
            type="selectInput"
            formPrefix=""
          />
        )}

        <Field
          name="bonusCard"
          className={classnames(styles.field, styles.bonusCard)}
          labelCode="consultation.form.cardnumber.label"
          placeholderCode="consultation.form.cardnumber.placeholder"
        />
        <Field
          name="comment"
          className={classnames(styles.field, styles.comment)}
          type="multiline"
          labelCode="consultation.form.message.label"
          placeholderCode="consultation.form.message.placeholder"
        />
        <Content
          name="appointmentConsent"
          content={reminderText}
          tag="span"
          size="sm"
          className={classnames(styles.field, styles.appointmentConsent)}
        />
        {isNewsletterEnabled && (
          <Field
            name="newsletterCheckbox"
            className={classnames(styles.field, styles.newsletterCheckbox)}
            checkboxText={newsletterDescription}
            type="checkbox"
          />
        )}

        {isSocialPartnerEnabled && (
          <Field
            name="socialPartnersCheckbox"
            className={classnames(styles.field, styles.socialPartnersCheckbox)}
            checkboxText={socialPartnersDescription}
            type="checkbox"
          />
        )}

        <Button
          className={styles.submit}
          type="submit"
          loading={loading}
          size="large"
          data-purpose="consultation.button.submit"
          onMouseEnter={handleHover}
          onClick={handleClick}
        >
          {submitText}
        </Button>
      </Form>
    </Layout>
  );
};

Register.propTypes = {
  isMobileLayout: PropTypes.bool,
};

Register.defaultProps = {
  isMobileLayout: false,
};

export default track(tagComponent('Register'))(Register);

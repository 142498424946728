/* eslint-disable react/require-default-props */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from 'pages/Consultation/components/TimeBlocks/components/RadioButton/RadioButton.scss';

const RadioButton = (props) => {
  const {
    ariaLabel,
    className,
    isChecked = false,
    isDisabled = false,
    id,
    name,
    onChange,
    onKeyDown,
    onClick,
    'data-purpose': dataPurpose,
  } = props;

  const onKeyDownHandler = useCallback(
    (e) => {
      !isDisabled && onKeyDown(e);
    },
    [isDisabled, onKeyDown],
  );

  const onClickHandler = useCallback(
    (e) => {
      !isDisabled && onClick(e);
    },
    [isDisabled, onClick],
  );

  return (
    <>
      <input
        aria-checked={isChecked}
        aria-label={ariaLabel}
        className={classnames(styles.radioButton, className)}
        checked={isChecked}
        type="radio"
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChange}
        onFocus={onChange}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <label
        onKeyDown={onKeyDownHandler}
        onClick={onClickHandler}
        htmlFor={id}
        data-purpose={dataPurpose}
      >
        {props.children}
      </label>
    </>
  );
};

RadioButton.propTypes = {
  /** aria-label for input */
  ariaLabel: PropTypes.string,
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  children: PropTypes.node,
  /** checked prop for input */
  isChecked: PropTypes.bool,
  /** disable input  */
  isDisabled: PropTypes.bool,
  /** id of the input */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** onChange callback of Input */
  onChange: PropTypes.func.isRequired,
  /** onKeyDown callback of Input */
  onKeyDown: PropTypes.func.isRequired,
  /** onClick callback of Input */
  onClick: PropTypes.func.isRequired,
  /** Data-purpose attribute for label of RadioButton */
  'data-purpose': PropTypes.string,
};

export default RadioButton;

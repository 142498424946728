import React from 'react';
import { getTrackingAreaData } from 'pages/Consultation/utils/area';

const EMPTY_STORE = {
  name: null,
  address: {
    streetname: null,
    streetnumber: null,
    postalCode: null,
    town: null,
  },
  openingHours: null,
};

export const ConsultationContext = React.createContext(null);

const getActiveView = (init) => {
  const { appointmentId, storeId, areaId, timendoMasterId, interventionId, groupId } = init;
  let activeView = 'start';

  // Already return the view, otherwise it will be overwritten by areaId or interventionId && groupId
  if (appointmentId && storeId) {
    return 'cancelInquiry';
  }

  if (areaId || timendoMasterId) {
    activeView = 'search';
  }

  if (interventionId && groupId) {
    activeView = 'month';
  }

  return activeView;
};

/**
 * Initialize State with empty store and empty timeslot
 */
export const initConsultationReducer = (init) => {
  const baseView = getActiveView(init);
  return {
    baseView,
    activeView: baseView, // start, search, month, day, register, finish
    appointmentId: init.appointmentId,
    areaId: init.areaId,
    timendoMasterId: init.timendoMasterId,
    area: null,
    trackingData: null,
    scrollToConsultation: baseView === 'cancelInquiry',
    interventionId: init.interventionId,
    groupId: init.groupId,
    storeId: init.storeId,
    productCode: init.productCode,
    productName: init.productName,
    store: EMPTY_STORE,
    form: {},
    date: null, // for now a object { day, month, year }
    submitErrors: [],
    timeslot: {
      calendarIds: null,
      start: null,
      date: null,
      hour: null,
    },
    headlineSeo: init.headlineSeo,
  };
};

export const consultationReducer = (state, action = {}) => {
  switch (action.type) {
    case 'SET_INTERVENTION_ID':
      return { ...state, interventionId: action.payload };
    case 'SET_GROUP_ID':
      return { ...state, groupId: action.payload };
    case 'SET_PRODUCT':
      return { ...state, productName: action.payload, activeView: 'search' };
    case 'SET_AREA':
      return {
        ...state,
        area: action.payload,
        trackingData: getTrackingAreaData(action.payload),
        activeView: 'search',
      };

    case 'SET_STORE':
      return { ...state, store: action.payload, activeView: 'month', date: null };
    case 'SET_DATE':
      return {
        ...state,
        date: action.payload,
        activeView: action.payload === null || action.payload.day === null ? 'month' : 'day',
      };
    case 'SET_TIMESLOT':
      return { ...state, timeslot: action.payload, activeView: 'register' };
    case 'SET_CANCEL_APPOINTMENT':
      return {
        ...state,
        scrollToConsultation: true,
        appointmentId: action.payload.appointmentId,
        area: action.payload.areaInformation,
        trackingData: getTrackingAreaData(action.payload.areaInformation),
        store: action.payload.storeInformation,
        form: action.payload.customer,
        timeslot: { start: action.payload.start },
        activeView: 'cancelInquiry',
      };
    case 'SET_AREA_AND_STORE':
      return {
        ...state,
        area: action.payload.area,
        trackingData: getTrackingAreaData(action.payload.area),
        store: action.payload.store,
      };
    case 'BACK_TO_START':
      return { ...state, area: null, trackingData: null, activeView: 'start' };
    case 'BACK_TO_SEARCH':
      return { ...state, store: EMPTY_STORE, date: null, timeslot: null, activeView: 'search' };
    case 'BACK_TO_MONTH':
      return { ...state, date: null, timeslot: null, submitErrors: [], activeView: 'month' };
    case 'BACK_TO_DAY':
      return { ...state, timeslot: null, submitErrors: [], activeView: 'day' };
    case 'SET_FINISHED_APPOINTMENT':
      return { ...state, activeView: 'finish', appointmentId: action.payload };
    case 'SET_SUBMIT_ERRORS':
      return { ...state, submitErrors: action.payload };
    case 'SET_ACTIVE_VIEW':
      return { ...state, activeView: action.payload };
    case 'RESET_STATE':
      return initConsultationReducer({
        areaId: null,
        timendoMasterId: null,
        interventionId: null,
        groupId: null,
      });
    case 'UPDATE_FORM':
      return { ...state, form: action.payload };

    default:
      return state;
  }
};

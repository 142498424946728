/* eslint-disable react/require-default-props */

import React, { useMemo, useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { chevronLeft, chevronRight } from '@xxxlgroup/hydra-icons';
import track from 'react-tracking';

import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import { Heading, Loading, Link } from '@xxxlgroup/hydra-ui-components';
import useDayjs from 'hooks/useDayjs';

import { noop } from '@xxxlgroup/hydra-utils/common';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';

import styles from 'pages/Consultation/components/TimeBlocks/components/Controls/Controls.scss';

const defaultMonthFormat = {
  headline: 'MMMM YYYY',
  navigation: 'MMMM',
  ariaNavigation: 'MMMM',
};
const defaultDayFormat = {
  headline: 'dddd, D.MM.YYYY',
  navigation: 'dd, D.MMMM',
  ariaNavigation: 'dddd, D.MMMM',
};

const Controls = (props) => {
  const {
    day,
    month,
    year,
    period = 'month',
    dayFormat = defaultDayFormat,
    monthFormat = defaultMonthFormat,
    onChange = noop,
    isLoading = false,
  } = props;

  const { state } = useContext(ConsultationContext);
  const dayjs = useDayjs();

  const tracking = useTracking(props, 'Controls');

  const [formats] = useState(period === 'day' ? dayFormat : monthFormat);

  const date = useMemo(() => {
    const monthDate = dayjs().set('month', month).set('year', year);

    if (period === 'day') {
      return monthDate.set('date', day);
    }

    return monthDate;
  }, [day, month, year, period, dayjs]);

  const handleOnChange = useCallback(
    (newPeriod) => (event) => {
      const data = {
        day: period !== 'month' ? newPeriod.get('date') : null,
        month: newPeriod.get('month'),
        year: newPeriod.get('year'),
      };
      tracking(event, { ...data, area: state.trackingData });
      onChange(data);
    },
    [onChange, period, state.trackingData, tracking],
  );

  const previousPeriod = date.subtract(1, period);
  const nextPeriod = date.add(1, period);
  const disabledPreviousLink = isLoading || previousPeriod.isBefore(dayjs().startOf('month'));

  return (
    <div className={styles.controls}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        glyphBefore={chevronLeft}
        theme={disabledPreviousLink ? 'tertiary' : 'primary'}
        onClick={!disabledPreviousLink ? handleOnChange(previousPeriod) : undefined}
        className={{ [styles.disabledLink]: disabledPreviousLink }}
        aria-label={previousPeriod.format(formats.ariaNavigation)}
        data-purpose="consultation.controls.prev"
      >
        <span className={styles.buttonContent}>{previousPeriod.format(formats.navigation)}</span>
      </Link>

      {isLoading ? (
        <Loading type="dots" className={styles.loading} />
      ) : (
        <Heading
          level={4}
          content={date.format(formats.headline)}
          className={styles.heading}
          data-purpose="consultation.controls.headline"
        />
      )}

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        glyphAfter={chevronRight}
        theme={isLoading ? 'tertiary' : 'primary'}
        onClick={!isLoading ? handleOnChange(nextPeriod) : undefined}
        aria-label={nextPeriod.format(formats.ariaNavigation)}
        data-purpose="consultation.controls.next"
      >
        <span className={styles.buttonContent}>{nextPeriod.format(formats.navigation)}</span>
      </Link>
    </div>
  );
};

Controls.propTypes = {
  /** Currently displayed day in headline */
  day: PropTypes.number,
  /** date format of headline and navigation buttons, if period is 'day' */
  dayFormat: PropTypes.shape({
    current: PropTypes.string,
    navigation: PropTypes.string,
  }),
  /** if loading the data */
  isLoading: PropTypes.bool,
  /** Currently displayed month in headline */
  month: PropTypes.number.isRequired,
  /** date format of headline and navigation buttons, if period is 'month' */
  monthFormat: PropTypes.shape({
    current: PropTypes.string,
    navigation: PropTypes.string,
    ariaNavigation: PropTypes.string,
  }),
  /** is called after navigation of day or month */
  onChange: PropTypes.func,
  /** depending on period "month" or "day", the headline/navigation is formatted differently */
  period: PropTypes.oneOf(['month', 'day']),
  /** Currently displayed year in headline */
  year: PropTypes.number.isRequired,
};

export default track(tagComponent('Controls'))(Controls);

import React, { useContext, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { Button, Content, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import Controls from 'pages/Consultation/components/TimeBlocks/components/Controls';
import styles from 'pages/Consultation/components/TimeBlocks/components/Day/Day.scss';

import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import Message from 'components/Message';
import parseTimeslotDate from 'pages/Consultation/utils/parseTimeslotDate';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import Box from 'components/Box';
import ConsultationHeading from 'pages/Consultation/components/ConsultationHeading';
import useDayjsUtc from 'hooks/useDayjsUtc';
import RadioButton from 'pages/Consultation/components/TimeBlocks/components/RadioButton';
import classnames from 'classnames';
import useMessage from 'components/Message/useMessage';

const getIsActiveTimeslot = (timeslot, renderTimeslot) => timeslot === renderTimeslot;

const Day = (props) => {
  const {
    currentDay,
    currentMonth,
    currentYear,
    calendarData,
    onDateChange,
    findNextAvailableDay,
  } = props;
  const tracking = useTracking(props, 'Day');
  const dayjsUtc = useDayjsUtc();
  const {
    state: { timeslot, trackingData },
    dispatch,
  } = useContext(ConsultationContext);
  const [nextDay, setNextDay] = useState(null);
  const [selectedSlotKey, setSelectedSlotKey] = useState();
  const selectedTimeSlot = useRef(null);
  const submitText = useMessage('accessibility.contactcenter.nextStep');

  const onClickTimeslot = useCallback(
    (timeSlot) => (event) => {
      const data = { timeSlot, area: trackingData };
      tracking(event, data);
      dispatch({
        type: 'SET_TIMESLOT',
        payload: timeSlot,
      });
    },
    [dispatch, tracking, trackingData],
  );

  const onChangeCallback = useCallback(
    (slot) => () => {
      setSelectedSlotKey(slot.start);
      selectedTimeSlot.current = slot;
    },
    [],
  );

  const loadNextDay = async (dayData) => {
    const foundNextDay = await findNextAvailableDay(dayData);
    setNextDay(foundNextDay || false);
  };

  const renderNextDay = () => {
    const foundTimeslot = nextDay.timeslots[0];
    const parsedTimeslot = parseTimeslotDate(foundTimeslot, dayjsUtc);
    const formatted = parsedTimeslot.format('dddd, DD.MM.YYYY');
    const handleOnClick = (event) => {
      onDateChange({
        day: foundTimeslot.date,
        month: parsedTimeslot.month(),
        year: parsedTimeslot.year(),
      });
      setNextDay(null);
      tracking(event, { ...trackingData, day: parsedTimeslot.format('dddd') });
    };
    return (
      <Message code={['consultation.day.notAvailableLine1', 'consultation.day.notAvailableLine2']}>
        {([searchNextLine1, searchNextLine2]) => (
          <>
            <Content tag="p" content={searchNextLine1} />
            <Content tag="p" content={searchNextLine2} />
            <Box marginTop="base">
              <Button
                variant="secondary"
                onClick={handleOnClick}
                aria-label={formatted}
                glyphAfter={arrowRight}
                data-purpose="consultation.day.findNextAvailableDay"
              >
                {formatted}
              </Button>
            </Box>
          </>
        )}
      </Message>
    );
  };

  const renderTimeslots = (dayData) => (
    <form>
      <div className={styles.slots}>
        {dayData.timeslots.map((renderTimeslot) => {
          const start = parseTimeslotDate(renderTimeslot, dayjsUtc).format('HH:mm');
          const active = getIsActiveTimeslot(timeslot, renderTimeslot);
          return (
            <RadioButton
              ariaLabel={start}
              className={classnames(active && styles.active)}
              key={renderTimeslot.start}
              id={renderTimeslot.start}
              isChecked={renderTimeslot.start === selectedSlotKey}
              name="timeslot"
              onKeyDown={onClickTimeslot(renderTimeslot)}
              onClick={onClickTimeslot(renderTimeslot)}
              onChange={onChangeCallback(renderTimeslot)}
              data-purpose="consultation.day.availableTimeslots"
            >
              <span>{start}</span>
            </RadioButton>
          );
        })}
      </div>
      <HydraLink
        onClick={(e) => {
          e.preventDefault();
          selectedTimeSlot.current !== null && onClickTimeslot(selectedTimeSlot.current)(e);
        }}
        className={styles.submit}
        type="submit"
        glyphAfter={arrowRight}
        aria-label="submit"
      >
        {submitText}
      </HydraLink>
    </form>
  );

  const renderDayView = () => {
    const dayData = calendarData[currentDay - 1];
    if (!dayData) {
      return null;
    }
    // Case: current day doesn't have timeslots, but a next day (loaded by case below)
    if (!dayData.available && nextDay) {
      return renderNextDay();
    }
    // Case: current day doesn't have timeslots, search in future
    if (!dayData.available && nextDay !== false) {
      loadNextDay(dayData);
      return null;
    }
    // Case: no timeslots, neither in future found, thats why nextDay is false
    if (!dayData.available && nextDay === false) {
      return <Content tag="p" content={<Message code="consultation.day.notAvailableLine1" />} />;
    }
    // Case: we got timeslots!
    return renderTimeslots(dayData);
  };

  const handleDateChange = (newDate) => {
    onDateChange(newDate);
    setNextDay(null);
  };

  if (!currentDay) {
    return null;
  }

  return (
    <>
      <Message key="controlInfo" code={['consultation.day.title', 'consultation.day.subTitle']}>
        {([title, subTitle]) => (
          <ConsultationHeading content={title} subContent={subTitle} noSubContentOnMobile />
        )}
      </Message>
      <Controls
        period="day"
        day={currentDay}
        year={currentYear}
        onChange={handleDateChange}
        month={currentMonth}
      />
      {renderDayView()}
    </>
  );
};

Day.propTypes = {
  /** array of days in current month, contains all available timeslots for each day */
  calendarData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      day: PropTypes.number,
      timeslots: PropTypes.arrayOf(PropTypes.shape({})),
      available: PropTypes.bool,
    }),
  ).isRequired,
  /** timeslots are shown for given currentDay */
  currentDay: PropTypes.number.isRequired,
  /** timeslots are shown for given currentMonth */
  currentMonth: PropTypes.number.isRequired,
  /** timeslots are shown for given currentYear */
  currentYear: PropTypes.number.isRequired,
  /** callback for a day-change in the day-navigation */
  onDateChange: PropTypes.func.isRequired,
  /** callback, which searchs for the next available day */
  findNextAvailableDay: PropTypes.func.isRequired,
};

export default track(tagComponent('Day'))(Day);

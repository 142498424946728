import React, { useState, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import Subsidiary from 'pages/Consultation/components/SubsidiarySearch/components/Subsidiaries/components/Subsidiary';
import { chevronDown, chevronUp } from '@xxxlgroup/hydra-icons';
import Message from 'components/Message';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { ConsultationContext } from 'pages/Consultation/Consultation.state';
import Box from 'components/Box';
import styles from 'pages/Consultation/components/SubsidiarySearch/components/Subsidiaries/Subsidiaries.scss';

const Subsidiaries = (props) => {
  const { subsidiaries, showCount, ...rest } = props;
  const { state } = useContext(ConsultationContext);
  const tracking = useTracking(props, 'Subsidiaries');
  const [showAll, setShowAll] = useState(false);
  const tabRef = useRef(null);

  const handleFocus = () => tabRef?.current?.focus();

  const handleShowMore = useCallback(
    (event) => {
      const data = { area: state.trackingData };
      tracking(event, data);

      setShowAll(!showAll);
      !showAll && requestAnimationFrame(handleFocus);
    },
    [showAll, state.trackingData, tracking],
  );

  const shownSubsidiaries = showAll ? subsidiaries : subsidiaries.slice(0, showCount);

  return (
    <>
      <ul className={styles.subsidiaries}>
        {shownSubsidiaries.map((subsidiary, index) => (
          <li key={subsidiary.timendoGroupId}>
            <Subsidiary
              key={subsidiary.timendoGroupId}
              subsidiary={subsidiary}
              data-testid="subsidiary"
              element={index === 4 ? tabRef : null}
            />
          </li>
        ))}
      </ul>
      {subsidiaries.length > showCount && (
        <Box className={styles.subsidiariesBox}>
          <Message code={['consultation.search.showMore', 'consultation.search.showLess']}>
            {([showMore, showLess]) => (
              <HydraLink
                data-purpose="consultation.search.showMore"
                glyphAfter={showAll ? chevronUp : chevronDown}
                onClick={handleShowMore}
                {...rest}
              >
                {!showAll ? showMore : showLess}
              </HydraLink>
            )}
          </Message>
        </Box>
      )}
      {isArrayEmpty(subsidiaries) && (
        <Box
          padding="base"
          display="flex"
          justifyContent="middle"
          alignItems="middle"
          data-purpose="no-subsidiary-found"
        >
          <Message code="consultation.search.error.noSubsidiaryFound" />
        </Box>
      )}
    </>
  );
};

Subsidiaries.propTypes = {
  subsidiaries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showCount: PropTypes.number,
};

Subsidiaries.defaultProps = {
  showCount: 4,
};
export default track(tagComponent('Subsidiaries'))(Subsidiaries);
